import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpHeaders, HttpErrorResponse, HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError, Observable } from "rxjs";
import { GlobalService } from "../shared/global.service";
import { StorageService } from "./storage.service";
import { NzModalService } from "ng-zorro-antd/modal";
@Injectable()
export class AuthService {
	private baseUrl: string;

	constructor(
		private myRoute: Router,
		private http: HttpClient,
		private g: GlobalService,
		private storage: StorageService,
		private modal: NzModalService
	) {
		this.baseUrl = this.g.phpPath;
	}

	/**
	 * @desc set a token in a sessionStorage variable
	 * @param token -> token value
	 * @return void
	 */
	saveUser(token: string) {
		sessionStorage.setItem("LoggedInUser", token);
	}

	/**
	 * @desc get the token in sessionStorage
	 * @param -
	 * @return boolean: true if user is logged-in, false otherwise
	 */
	getUser() {
		return JSON.parse(sessionStorage.getItem("LoggedInUser"));
	}

	/**
	 * @desc remove saved User from session storage
	 * @param -
	 * @return void
	 */
	resetUser() {
		sessionStorage.removeItem("LoggedInUser");
	}

	/**
	 * @desc get the token in sessionStorage
	 * @param -
	 * @return boolean: true if user is logged-in, false otherwise
	 */
	isLoggedIn() {
		return this.getUser() !== null;
	}

	/**
	 * @desc remove a token in a sessionStorage variable and navigate in login page
	 * @param -
	 * @return void
	 */
	logout() {
		this.modal.confirm({
			nzTitle: "Sei sicuro di voler uscire?",
			nzContent: "Prima di confermare ricordati di salvare il lavoro in corso...",
			nzOnOk: () => {
				this.g.resetQuote();
				this.storage.removeSelectedQuote();
				this.storage.removeSelectedImages();
				this.resetUser();
				this.resetIsSeller();
				this.myRoute.navigate(["login"]);
				this.g.actualPage = "login";
			},
		});

		// this.g.resetQuote();
		// this.storage.removeSelectedQuote();
		// this.storage.removeSelectedImages();
		// this.resetUser();
		// this.resetIsSeller();
		// this.myRoute.navigate(["login"]);
		// this.g.actualPage = "login";
	}

	/**
	 * @desc call backend and check if inputed password is the same key storaged in database
	 * @param formData -> contains inputed password
	 * @return Observable<Error | any>, error if key is different, any otherwise
	 */
	checkLogin(formData: FormData): Observable<Error | any> {
		const header = new HttpHeaders();
		header.append("Content-Type", "application/json");
		return this.http
			.post<any>(this.baseUrl + "/login.php", formData, { headers: header })
			.pipe(
				catchError((err: HttpErrorResponse) => {
					if (err.error instanceof Error) {
						// A client-side or network error occurred
						const details = { detail: err.error, status: err.status };
						return throwError(details);
					} else {
						// A backend error occured (err.status = 500)
						const details = { detail: err.error, status: err.status };
						return throwError(details);
					}
				})
			);
	}

	/**
	 * @desc set a token in a sessionStorage variable
	 * @param token -> token value
	 * @return void
	 */
	setIsSeller(token: string) {
		sessionStorage.setItem("isSeller", token);
	}

	/**
	 * @desc get the token in sessionStorage
	 * @param -
	 * @return boolean: true if user is logged-in, false otherwise
	 */
	getIsSeller() {
		return sessionStorage.getItem("isSeller");
	}

	/**
	 * @desc remove saved User from session storage
	 * @param -
	 * @return void
	 */
	resetIsSeller() {
		sessionStorage.removeItem("isSeller");
	}
}
