import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { TblClienti } from "src/app/entities/TblClienti";
import { TblContattiClienti } from "src/app/entities/TblContattiClienti";
import { TblTipoClienti } from "src/app/entities/TblTipoClienti";
import { TblVenditori } from "src/app/entities/TblVenditori";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { GlobalService } from "src/app/shared/global.service";

@Component({
	selector: "app-customers",
	templateUrl: "./customers.component.html",
	styleUrls: ["./customers.component.scss"],
})
export class CustomersComponent implements OnInit {
	CryptoJS = require("crypto-js");
	showForm = false;

	dataResult: any[];
	contResult: TblContattiClienti[] = [];
	displayedData: any[];
	loading = true;
	sellers: { label: string }[] = [];
	groups: { label: string; nome: string }[] = [];
	expandSet = "";
	custForm: FormGroup;
	contactForm: FormGroup;
	saveForm: FormGroup;
	contactFromsArray: FormArray;
	tabs = [];
	lastP: string;
	showSave: boolean = false;

	tempSaveCode: string;

	constructor(
		private data: DataService,
		private message: NzMessageService,
		private g: GlobalService,
		private translate: TranslateService,
		private auth: AuthService,
		private fb: FormBuilder
	) {}

	ngOnInit(): void {
		let isSeller = this.auth.getIsSeller();
		// get all customers
		if (isSeller !== "null") {
			this.data.getCustomersBySeller(isSeller.toString()).subscribe(
				(result) => {
					this.dataResult = result as any[];
					this.displayedData = [...this.dataResult];
					this.loading = false;
				},
				(error) => {
					this.message.create("error", "Errore", { nzDuration: 5000 });
					this.loading = false;
					console.error("GET TABLE: ", error);
				}
			);
		} else {
			this.data.getTable("customers").subscribe(
				(result) => {
					this.dataResult = result as any[];
					this.displayedData = [...this.dataResult];
					this.loading = false;
				},

				(error) => {
					this.message.create("error", "Errore", { nzDuration: 5000 });
					this.loading = false;
					console.error("GET TABLE: ", error);
				}
			);
		}

		this.data.getTable("sellers").subscribe(
			(result) => {
				const sellersArray = result as TblVenditori[];
				sellersArray.forEach((s) => {
					this.sellers.push({ label: s.cd_venditore + ": " + s.nome + " " + s.cognome });
				});
			},
			(error) => {
				this.message.create("error", "Errore", { nzDuration: 5000 });
				this.loading = false;
				console.error("GET TABLE: ", error);
			}
		);

		this.data.getTable("type").subscribe(
			(result) => {
				const groupsArray = result as TblTipoClienti[];
				groupsArray.forEach((g) => {
					this.groups.push({
						label: g.cd_cfgruppo1,
						nome: g.nome,
					});
				});
			},
			(error) => {
				this.message.create("error", "Errore", { nzDuration: 5000 });
				this.loading = false;
				console.error("GET TABLE: ", error);
			}
		);

		this.contactForm = this.fb.group({
			contacts: this.fb.array([]),
		});
	}

	filter(searchValue: string) {
		this.displayedData = this.dataResult.filter(
			(item: TblClienti) =>
				item.descrizione.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
				item.cd_cf.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
		);
	}

	onExpandChange(cd_cf, checked: boolean): void {
		console.log("CHECK", checked);
		if (checked) {
			console.log(cd_cf);
			this.data.getCustomerContacts(cd_cf).subscribe(
				(result) => {
					const contactsArray = result as TblContattiClienti[];
					this.contResult = contactsArray;
					this.expandSet = cd_cf;
				},
				(error) => {
					this.message.create("error", "Errore", { nzDuration: 5000 });
					this.loading = false;
					console.error("GET TABLE: ", error);
				}
			);
		} else {
			this.expandSet = "";
		}
	}

	showModal() {
		this.data.getLastTemporary().subscribe(
			(result) => {
				console.log(result);
				if (result.length <= 0) {
					this.lastP = "P1";
				} else {
					this.lastP = "P" + (Number.parseInt(result[result.length - 1].cd_cf.substring(1)) + 1);
				}
			},
			(error) => {
				this.message.create("error", "Errore", { nzDuration: 5000 });
				this.loading = false;
				console.error("GET TABLE: ", error);
			}
		);

		this.showForm = true;
		this.custForm = this.fb.group({
			descrizione: new FormControl("", [Validators.required]),
			indirizzo: new FormControl("", [Validators.required]),
			localita: new FormControl("", [Validators.required]),
			cap: new FormControl("", [Validators.required]),
			cd_provincia: new FormControl("", [Validators.required]),
			cd_nazione: new FormControl("", [Validators.required]),
			piva: new FormControl("", [Validators.required]),
			cf: new FormControl("", [Validators.required]),
			gruppo: new FormControl("", [Validators.required]),
			note: new FormControl(""),
			venditore: new FormControl(""),
		});
	}

	addContactRow(): void {
		(<FormArray>this.contactForm.get("contacts")).push(
			this.fb.group({
				nome: new FormControl("", [Validators.required]),
				cognome: new FormControl(""),
				titolo: new FormControl(""),
				tel: new FormControl(""),
				tel2: new FormControl(""),
				cell: new FormControl(""),
				cell2: new FormControl(""),
				email: new FormControl(""),
				pec: new FormControl(""),
				url: new FormControl(""),
				note: new FormControl(""),
			})
		);
	}

	handleCancel() {
		this.showForm = false;
	}

	async addCustomer() {
		const checkSumKey = await this.g.encryptDataGlobal(
			"TblClienti" + this.custForm.value.descrizione + this.custForm.value.piva
		);
		const formData = new FormData();
		formData.append("cd_cf", this.lastP);
		formData.append("descrizione", this.custForm.value.descrizione);
		formData.append("indirizzo", this.custForm.value.indirizzo);
		formData.append("localita", this.custForm.value.localita);
		formData.append("cap", this.custForm.value.cap);
		formData.append("cd_provincia", this.custForm.value.cd_provincia);
		formData.append("cd_nazione", this.custForm.value.cd_nazione);
		formData.append("piva", this.custForm.value.piva);
		formData.append("cf", this.custForm.value.cf);
		formData.append("gruppo", this.custForm.value.gruppo);
		formData.append("note", this.custForm.value.note);
		formData.append("venditore", this.custForm.value.venditore);
		formData.append("provvisiorio", "1");
		formData.append("checkKey", checkSumKey);

		this.data.addCustomer(formData).subscribe(
			async (result) => {
				let contArray = (<FormArray>this.contactForm.get("contacts")).value;
				let dataArray: TblContattiClienti[] = [];
				for (let i = 0; i < contArray.length; i++) {
					const contact = contArray[i];
					let row: TblContattiClienti = new TblContattiClienti();
					row.cd_cf = this.lastP;
					row.nome = contact.nome;
					row.cognome = contact.cognome;
					row.titolo = contact.titolo;
					row.telefono = contact.tel;
					row.telefono2 = contact.tel2;
					row.cellulare = contact.cell;
					row.cellulare2 = contact.cell2;
					row.email = contact.email;
					row.emailpec = contact.pec;
					row.url = contact.url;
					row.note = contact.note;
					row.sequenza = i + 1;
					dataArray.push(row);
				}
				console.log(dataArray);
				const formData = new FormData();
				formData.append("array", JSON.stringify(dataArray));
				this.data.addCustomerContact(formData).subscribe(
					(result) => {
						this.message.create("success", "Customer added succesfully", { nzDuration: 5000 });
						this.showForm = false;
						this.tabs = [];
						this.contactForm.reset();
						this.custForm.reset();
						this.ngOnInit();
					},
					(error) => {
						this.message.create("error", "Errore", { nzDuration: 5000 });
						console.error("ADD CONTACT:", error);
					}
				);
			},
			(error) => {
				this.message.create("error", "Errore", { nzDuration: 5000 });
				console.error("ADD CUST", error);
			}
		);
	}

	closeTab({ index }: { index: number }) {
		this.tabs.splice(index - 1, 1);
		(<FormArray>this.contactForm.get("contacts")).removeAt(index - 1);
	}

	newTab(): void {
		this.addContactRow();
		let index: number = this.tabs.length + 1;
		this.tabs.push("Contatto" + index);
	}

	async deleteCustomer(cd) {
		const checkSumKey = await this.g.encryptDataGlobal("TblClienti" + cd);
		const formData = new FormData();
		formData.append("id", cd);
		formData.append("checkKey", checkSumKey);
		this.data.deleteCustomer(formData).subscribe(
			(result) => {
				this.dataResult = this.dataResult.filter((d) => d.cd_cf.toString() !== cd.toString());
				this.displayedData = [...this.dataResult];
				this.message.create("success", "Customer deleted successfully", { nzDuration: 5000 });
			},
			(error) => {
				this.message.create("error", "Errore", { nzDuration: 5000 });
			}
		);
	}

	showModalSave(cd) {
		this.tempSaveCode = cd;
		this.showSave = true;

		this.saveForm = this.fb.group({
			code: new FormControl("", [Validators.required]),
		});
	}

	handleCancelSave() {
		this.tempSaveCode = "";
		this.showSave = false;
	}

	async saveCustomer() {
		const checkSumKey = await this.g.encryptDataGlobal("TblClienti" + this.saveForm.value.code);
		const formData = new FormData();
		formData.append("cd_cf", this.tempSaveCode);
		formData.append("code", this.saveForm.value.code);
		formData.append("checkKey", checkSumKey);

		this.data.editCustomerCode(formData).subscribe(
			(result) => {
				this.tempSaveCode = "";
				this.showSave = false;
				this.saveForm.reset();
				this.ngOnInit();
				this.message.create("success", "Customer edited successfully", { nzDuration: 5000 });
			},
			(error) => {
				this.message.create("error", "Errore", { nzDuration: 5000 });
				console.error("EDIT CUST", error);
			}
		);
	}
}
