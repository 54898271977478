import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NzImageService } from 'ng-zorro-antd/image';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TblProdotti } from 'src/app/entities/TblProdotti';
import { TblVenditori } from 'src/app/entities/TblVenditori';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  CryptoJS = require('crypto-js');
  showForm = false;
  sellers: { label: string; }[] = [];
  dataResult: TblProdotti[];
  displayedData: any[];
  loading = true;
  editCache: { [key: string]: { edit: boolean; data: TblProdotti; }; } = {};
  userForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private message: NzMessageService,
    private g: GlobalService,
    private modal: NzModalService,
    private router: Router,
    private nzImageService: NzImageService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    this.data.getTable('products').subscribe(
      (result) => {
        this.dataResult = result as TblProdotti[];
        this.displayedData = [...this.dataResult];
        this.loading = false;
        this.displayedData.forEach((data) => {
          data.visible = false;
        });
        this.updateEditCache();
        this.data.getTable('sellers').subscribe(
          (result) => {
            const sellersArray = result as TblVenditori[];
            sellersArray.forEach((s) => {
              this.sellers.push({
                label: s.cd_venditore + ': ' + s.nome + ' ' + s.cognome,
              });
            });
          },
          (error) => {
            this.message.create('error', 'Errore', { nzDuration: 5000 });
            this.loading = false;
            console.error('GET TABLE: ', error);
          }
        );
      },
      (error) => {
        this.message.create('error', 'Errore', { nzDuration: 5000 });
        this.loading = false;
        console.error('GET TABLE: ', error);
      }
    );
  }

  updateEditCache(): void {
    this.dataResult.forEach((item) => {
      this.editCache[item.cd_prodotto] = {
        edit: false,
        data: { ...item },
      };
    });
  }

  filter(searchValue: string) {
    this.displayedData = this.dataResult.filter(
      (item: TblProdotti) =>
        item.cd_prodotto.toLowerCase().indexOf(searchValue.toLowerCase()) !==
        -1 ||
        item.nome.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );
  }

  showDescription(cd): void {
    let i = this.displayedData.findIndex((data) => data.cd_prodotto === cd);
    this.modal.create({
      nzContent: this.displayedData[i].descrizione,
      nzClosable: true,
      nzFooter: null,
      nzOnOk: () => { },
    });
  }

  showNote(cd): void {
    let i = this.displayedData.findIndex((data) => data.cd_prodotto === cd);
    this.modal.create({
      nzContent: this.displayedData[i].note,
      nzClosable: true,
      nzFooter: null,
      nzOnOk: () => { },
    });
  }

  showNoteInterne(cd): void {
    let i = this.displayedData.findIndex((data) => data.cd_prodotto === cd);
    this.modal.create({
      nzContent: this.displayedData[i].note_interne,
      nzClosable: true,
      nzFooter: null,
      nzOnOk: () => { },
    });
  }

  addProduct() {
    this.router.navigateByUrl('/product-add');
  }

  editProduct(cd) {
    this.router.navigateByUrl('/product-edit/' + cd);
  }

  async deleteProduct(cd) {
    const checkSumKey = await this.g.encryptDataGlobal(
      'TblProdotti' + this.auth.getUser().cd_utente + this.auth.getUser().email
    );
    const formData = new FormData();
    formData.append('cf', this.auth.getUser().cd_utente);
    formData.append('email', this.auth.getUser().email);
    formData.append('cd', cd);
    formData.append('checkKey', checkSumKey);

    this.data.deleteProduct(formData).subscribe(
      (result) => {
        this.dataResult = this.dataResult.filter((d) => d.cd_prodotto !== cd);
        this.displayedData = [...this.dataResult];
      },
      (error) => {
        console.error('Unable to get last: ', error);
      }
    );
  }

  showImages(cd): void {
    let i = this.displayedData.findIndex((data) => data.cd_prodotto === cd);
    const row = this.displayedData[i].immagini;
    const images = [];
    row.forEach((img) => {
      images.push({
        src: this.g.imgPath + 'prodotti/' + img,
      });
      console.log(this.g.imgPath + 'prodotti/' + img);
    });
    this.nzImageService.preview(images, { nzZoom: 1.5, nzRotate: 0 });
  }

  exportFile() {
    let excelData = this.dataResult as any[];
    console.log(excelData);
    for (let i = 0; i < excelData.length; i++) {
      delete excelData[i].visible;
      delete excelData[i].immagini;
    }
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    XLSX.writeFile(workbook, this.toExportFileName('products'));
  }

  toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
  }
}
