<nz-layout class="uiUserAdminInnerLayout">
    <nz-content>
        <div class="uiAdminAddContainer">
            <button nz-button nzType="primary" class="uiAdminAddBtn uiAdminLeftAddBtn" (click)="exportFile()">
                <i class="fad fa-file-spreadsheet"></i>
                {{'prodExport' | translate}}
            </button>
            <button nz-button nzType="primary" class="uiAdminAddBtn" (click)="addProduct()">
                <i class="fad fa-plus-square"></i>
                {{'btn.add' | translate}}
            </button>
        </div>
        <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input placeholder="Ricerca" (input)="filter($event.target.value)" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <i class="fad fa-search"></i>
        </ng-template>
        <nz-table #dataTable nzBordered nzSize="middle" [nzData]="displayedData" [nzPageSize]="20" [nzLoading]="loading" nzPaginationPosition="both" [nzSimple]="true" class="uiAdminTable">
            <thead>
                <tr>
                    <th nzAlign="center" nzWidth="50px">{{'prodId' | translate}}</th>
                    <th nzAlign="center" nzWidth="25%">{{'prodName' | translate}}</th>
                    <th nzAlign="center" nzWidth="200px">{{'prodDesc' | translate}}</th>
                    <th nzAlign="center" nzWidth="50px">{{'prodFam' | translate}}</th>
                    <th nzAlign="center">{{'prodNote' | translate}}</th>
                    <th nzAlign="center">{{'prodInt' | translate}}</th>
                    <th nzAlign="center" nzWidth="50px">{{'prodUmQ' | translate}}</th>
                    <th nzAlign="center" nzWidth="70px">{{'prodSup' | translate}}</th>
                    <th nzAlign="center" nzWidth="70px">{{'prodMatS' | translate}}</th>
                    <th nzAlign="center" nzWidth="70px">{{'prodMatU' | translate}}</th>
                    <th nzAlign="center" nzWidth="70px">{{'prodManU' | translate}}</th>
                    <th nzAlign="center" nzWidth="70px">{{'prodManP' | translate}}</th>
                    <th nzAlign="center" nzWidth="70px">{{'prodRic' | translate}}</th>
                    <th nzAlign="center" nzWidth="80px">{{'prodImm' | translate}}</th>
                    <th nzAlign="center" nzWidth="30px"></th>
                </tr>
            </thead>
            <tbody class="uiAdminTableBody">
                <tr *ngFor="let data of dataTable.data; let i = index" [ngClass]="i%2 !== 0 ? 'uiColoredRow' : ''">
                    <ng-container *ngIf="!editCache[data.cd_prodotto].edit">
                        <td nzLeft="0px" nzAlign="center">
                            {{data.cd_prodotto}}
                        </td>
                        <td nzLeft="0px" nzAlign="left">
                            {{data.nome}}
                        </td>
                        <td nzLeft="0px" nzAlign="left" class="uiEipsisCol">
                            <div [innerHTML]="data.descrizione">
                            </div>
                            <a (click)="showDescription(data.cd_prodotto)" class="uiOpenText" *ngIf="data.descrizione" nzTooltipTitle="{{'showDescription' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                                <i class="fad fa-search" style="font-size: 15px;"></i>
                            </a>
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.cd_fam}}
                        </td>
                        <td nzLeft="0px" nzAlign="left" class="uiEipsisCol">
                            <div [innerHTML]="data.note">
                            </div>
                            <a (click)="showNote(data.cd_prodotto)" class="uiOpenText" *ngIf="data.note" nzTooltipTitle="{{'showNotes' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                                <i class="fad fa-search" style="font-size: 15px;"></i>
                            </a>
                        </td>
                        <td nzLeft="0px" nzAlign="left" class="uiEipsisCol">
                            <div [innerHTML]="data.note_interne">
                            </div>
                            <a (click)="showNoteInterne(data.cd_prodotto)" class="uiOpenText" *ngIf="data.note_interne" nzTooltipTitle="{{'showIntNotes' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                                <i class="fad fa-search" style="font-size: 15px;"></i>
                            </a>
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.quantita}}
                            <nz-divider></nz-divider>
                            {{data.um}}
                        </td>
                        <td nzLeft="0px" nzAlign="right">
                            <span *ngIf="data.pr_fornitore > 0">{{data.pr_fornitore}} €</span>
                        </td>
                        <td nzLeft="0px" nzAlign="right">
                            <span *ngIf="data.sc_materiale > 0">{{data.sc_materiale}} %</span>
                        </td>
                        <td nzLeft="0px" nzAlign="right">
                            <span *ngIf="data.unit_materiale > 0">{{data.unit_materiale}} €</span>
                        </td>
                        <td nzLeft="0px" nzAlign="right">
                            <span *ngIf="data.unit_manodopera > 0">{{data.unit_manodopera}} €</span>
                        </td>
                        <td nzLeft="0px" nzAlign="right">
                            <span *ngIf="data.perc_manodopera > 0">{{data.perc_manodopera}} %</span>
                        </td>
                        <td nzLeft="0px" nzAlign="right">
                            {{data.ricarico}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <nz-badge *ngIf="data.immagini && data.immagini.length > 0" [nzCount]="data.immagini.length">
                                <a (click)="showImages(data.cd_prodotto)" nzTooltipTitle="{{'showImg' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                                    <i class="fad fa-images" style="font-size: 20px;"></i>
                                </a>
                            </nz-badge>
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <a class="uiAdminAddBtn" (click)="editProduct(data.cd_prodotto)" nzTooltipTitle="{{'tooltip.add' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                                <i class="fad fa-edit uiAdminUserAction"></i>
                            </a>
                            <br />
                            <br />
                            <a class="uiAdminAddBtn" nz-popconfirm nzPopconfirmTitle="{{ 'alertDelete' | translate }}" nzPopconfirmPlacement="top" (nzOnConfirm)="deleteProduct(data.cd_prodotto)" nzTooltipTitle="{{'tooltip.delete' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                                <i class="fad fa-trash uiAdminUserAction"></i>
                            </a>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </nz-table>
    </nz-content>
</nz-layout>