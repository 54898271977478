import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';
import { TranslateService } from '@ngx-translate/core';
import { TblUtenti } from 'src/app/entities/TblUtenti';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TblVenditori } from 'src/app/entities/TblVenditori';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  CryptoJS = require('crypto-js');

  showForm = false;

  sellers: {label: string}[] = [];
  dataResult: TblUtenti[];
  displayedData: any[];
  loading = true;
  editCache: { [key: string]: { edit: boolean; data: TblUtenti } } = {};
  userForm: FormGroup;
  constructor(private fb: FormBuilder, private data: DataService, private message: NzMessageService, private g: GlobalService, private translate: TranslateService) { }

  ngOnInit() {
    // get all users
    this.data.getTable('users').subscribe(
      result => {
        this.dataResult = result as TblUtenti[];
        this.displayedData = [
          ...this.dataResult
        ];
        this.loading = false;
        this.displayedData.forEach(data => {
          if (data.password) {
            data.password = this.decryptPwd(data.password);
          }
          data.visible = false;
        });
        this.updateEditCache();
        this.data.getTable('sellers').subscribe(
          result => {
            const sellersArray = result as TblVenditori[];
            sellersArray.forEach(s => {
              this.sellers.push({label: s.cd_venditore + ': '+ s.nome + ' ' + s.cognome});
            })
          },
          error => {
            this.message.create('error', 'Errore', { nzDuration: 5000 });
            this.loading = false;
             console.error('GET TABLE: ', error);
          }
        );
      },
      error => {
        this.message.create('error', 'Errore', { nzDuration: 5000 });
        this.loading = false;
         console.error('GET TABLE: ', error);
      }
    );
  }

  updateEditCache(): void {
    this.dataResult.forEach(item => {
      this.editCache[item.cd_utente] = {
        edit: false,
        data: { ...item }
      };
    });
  }

  filter(searchValue: string) {
    this.displayedData = this.dataResult.filter((item: TblUtenti) => item.email.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
      item.nome.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
  }

  /** 
  * @desc decrypt a string
  * @param data - encrypted string
  * @return decrypted string
  */
  decryptPwd(data: string) {
    const words = this.CryptoJS.enc.Base64.parse(data);
    const textString = this.CryptoJS.enc.Utf8.stringify(words);
    return textString;
  }

  async deleteUser(id: string) {
    const index = this.dataResult.findIndex(item => item.cd_utente.toString() === id.toString());
    const checkSumKey = await this.g.encryptDataGlobal('TblUtenti' + id);
    const formData = new FormData();
    formData.append('id', id);
    formData.append('checkKey', checkSumKey);
    this.data.deleteUser(formData).subscribe(
      result => {
        this.dataResult = this.dataResult.filter(d => d.cd_utente.toString() !== id.toString());
        this.displayedData = [
          ...this.dataResult
        ];
        this.message.create('success', 'User deleted successfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'Errore', { nzDuration: 5000 });
        console.error('CHANGE SWITCH: ', error);
      }
    );
  }

  startEdit(id: string): void {
    this.editCache[id].edit = true;
  }

  cancelEdit(id: string): void {
    const index = this.dataResult.findIndex(item => item.cd_utente.toString() === id.toString());
    this.editCache[id] = {
      data: { ...this.dataResult[index] },
      edit: false
    };
  }

  async saveEdit(id: string) {
    const crypt = this.CryptoJS.enc.Base64.stringify(this.CryptoJS.enc.Utf8.parse(this.editCache[id].data.password));
    const checkSumKey = await this.g.encryptDataGlobal('TblUtenti' + id + this.editCache[id].data.nome + this.editCache[id].data.cognome + this.editCache[id].data.email + 
    crypt);
    const formData = new FormData();
    formData.append('id', id);
    formData.append('nome', this.editCache[id].data.nome);
    formData.append('cognome', this.editCache[id].data.cognome);
    formData.append('venditore', this.editCache[id].data.venditore);
    formData.append('email', this.editCache[id].data.email);
    formData.append('ruolo', this.editCache[id].data.ruolo);
    formData.append('password', crypt);
    formData.append('checkKey', checkSumKey);

    this.data.editUser(formData).subscribe(
      result => {
        this.editCache[id].edit = false;
        const index = this.dataResult.findIndex(item => item.cd_utente.toString() === id.toString());
        Object.assign(this.dataResult[index], this.editCache[id].data);
        Object.assign(this.displayedData[index], this.dataResult[index]);
        this.message.create('success', 'User edited successfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'Errore', { nzDuration: 5000 });
        console.error('CHANGE SWITCH: ', error);
      }
    );
  }

  async changeUserSwitch(id: string, field: string) {
    const index = this.dataResult.findIndex(item => item.cd_utente.toString() === id.toString());
    const checkSumKey = await this.g.encryptDataGlobal('TblUtenti' + id + field);
    const formData = new FormData();
    formData.append('id', id);
    formData.append('campo', field);
    formData.append('checkKey', checkSumKey);

    this.data.editUserSwitch(formData).subscribe(
      result => {
        if (field === 'scrittura') {
          this.displayedData[index].scrittura = !this.displayedData[index].scrittura;
        } else if (field === 'attivo') {
          this.displayedData[index].attivo = !this.displayedData[index].attivo;
        }
        this.message.create('success', 'User edited successfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'Errore', { nzDuration: 5000 });
        console.error('CHANGE SWITCH: ', error);
      }
    );
  }

  showModal(): void {
    this.showForm = true;
    this.userForm = this.fb.group({
      nome: new FormControl('', [Validators.required]),
      cognome: new FormControl('', [Validators.required]),
      venditore: new FormControl(''),
      ruolo: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  handleCancel() {
    this.showForm = false;
  }

  async addUser() {
    const crypt = this.CryptoJS.enc.Base64.stringify(this.CryptoJS.enc.Utf8.parse(this.userForm.value.password));
    const checkSumKey = await this.g.encryptDataGlobal('TblUtenti' + this.userForm.value.nome + this.userForm.value.cognome + this.userForm.value.email + 
    crypt);
    const formData = new FormData();
    formData.append('nome', this.userForm.value.nome);
    formData.append('cognome', this.userForm.value.cognome);
    formData.append('venditore', this.userForm.value.venditore);
    formData.append('ruolo', this.userForm.value.ruolo);
    formData.append('email', this.userForm.value.email);
    formData.append('password', crypt);
    formData.append('checkKey', checkSumKey);

    this.data.addUser(formData).subscribe(
      result => {
        this.showForm = false;
        this.data.getTable('users').subscribe(
          result => {
            this.dataResult = result as TblUtenti[];
            console.log(this.dataResult);
            this.displayedData = [
              ...this.dataResult
            ];
            this.loading = false;
            this.displayedData.forEach(data => {
              if (data.password) {
                data.password = this.decryptPwd(data.password);
              }
              data.visible = false;
            });
            this.updateEditCache();
          },
          error => {
            this.message.create('error', 'Errore', { nzDuration: 5000 });
            this.loading = false;
             console.error('GET TABLE: ', error);
          }
        );
        this.message.create('success', 'User added succesfully', { nzDuration: 5000 });
      },
      error => {
        this.message.create('error', 'Errore', { nzDuration: 5000 });
        console.error('ADD USER ', error);
      }
    );
  }
}