<nz-layout class="uiUserAdminInnerLayout">
    <nz-content>
        <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input placeholder="Ricerca" (input)="filter($event.target.value)" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <i class="fad fa-search"></i>
        </ng-template>
        <nz-table #dataTable nzBordered nzSize="middle" [nzData]="displayedData" [nzPageSize]="30" [nzLoading]="loading" nzPaginationPosition="both" [nzSimple]="true" class="uiAdminTable">
            <thead>
                <tr>
                    <th nzAlign="center">Id</th>
                    <th nzWidth="30px" nzAlign="center">{{'quoteVersion' | translate}}</th>
                    <th nzAlign="center" nzWidth="400px">{{'quoteCustomer' | translate}}</th>
                    <th nzAlign="center">{{'quoteData' | translate}}</th>
                    <th nzAlign="center">{{'quoteSeller' | translate}}</th>
                    <th nzAlign="center">{{'quoteSector' | translate}}</th>
                    <th nzAlign="center">{{'quoteForn' | translate}}</th>
                    <th nzAlign="center">{{'quoteState' | translate}}</th>
                    <th nzAlign="center">{{'quoteUser' | translate}}</th>
                    <th nzWidth="30px" nzAlign="center"></th>
                </tr>
            </thead>
            <tbody class="uiAdminTableBody">
                <tr *ngFor="let data of dataTable.data; let i = index" [ngClass]="{'uiYellowRow': data.stato === 'In corso', 'uiGreyRow': data.stato === 'Bloccato'}">
                    <td nzLeft="0px" nzAlign="center">
                        {{data.cd_preventivo}}
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        {{data.versione}}
                    </td>
                    <td nzLeft="0px" nzAlign="left">
                        {{data.cd_cf}}
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        <div class="uiQuoteData" *ngIf="data.data_conferma">
                            <p class="uiQuoteDataLabel">Conferma</p>
                            <p style="margin: 0 0 5px 0; padding: 8px 0 0 0;">
                                {{data.data_conferma * 1000 | date: 'dd/MM/yyyy HH:mm:ss a'}}
                            </p>
                        </div>
                        <div>
                            <p class="uiQuoteDataLabel">Ultima modifica</p>
                            <p style="margin: 5px 0 0 0; padding: 8px 0 0 0;">
                                {{data.data_modifica * 1000 | date: 'dd/MM/yyyy HH:mm:ss a'}}</p>
                        </div>
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        {{data.venditore}}
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        {{data.tipologia}}
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        <p *ngIf="data.fornitura !== 'null'">{{data.fornitura}}</p>
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        {{data.stato}}
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        {{data.cd_utente}}
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        <button nz-button [disabled]="data.stato === 'In corso'" class="uiDownloadBtn" (click)="downloadPdf(data.cd_preventivo)" nzTooltipTitle="{{'downloadPdf' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                            <i class="fad fad-swap-color fa-download"></i>
                        </button>
                        <br />
                        <br />
                        <button nz-button [disabled]="data.stato === 'In corso'" class="uiDownloadBtn" (click)="openPdf(data.cd_preventivo)" nzTooltipTitle="{{'quotePreview' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                            <i class="fad fad-swap-color fa-file-pdf"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </nz-content>
</nz-layout>