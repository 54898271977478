import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { StorageService } from 'src/app/services/storage.service';
import { copyFileSync } from 'fs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-img-modal',
  templateUrl: './imgModal.component.html',
  styleUrls: ['./imgModal.component.scss']
})
export class ImgEditProductModalComponent implements OnInit {
  @Input() cd_prodotto: string;

  constructor(private modal: NzModalRef, private data: DataService, private message: NzMessageService, private translate: TranslateService, private g: GlobalService,
    private http: HttpClient, private elementRef: ElementRef, private storage: StorageService, private auth: AuthService) { }
  dataResult: any[] = [];
  initialImgs: any[] = [];
  loading = true;

  previewImage: string | undefined = '';
  previewVisible = false;
  uploadURL = this.g.phpPath + '/products/addProductImage.php';


  ngOnInit(): void {
    this.loading = false;
    this.data.getProductImages(this.cd_prodotto).subscribe(
      (result) => {
        let selectedImgs: string[] = this.storage.getSelectedImages();
        let jsonResult;
        if (result) {
          jsonResult = JSON.parse(result);
        } else {
          jsonResult = JSON.parse('[]');
        }
        if (jsonResult.length > 0) {
          jsonResult.forEach(el => {
            let link = this.g.imgPath + 'prodotti/' + el;
            let selected;
            if (selectedImgs.includes(el)) {
              selected = true;
            } else {
              selected = false;
            }
            this.dataResult.push(
              {
                id: this.dataResult.length,
                name: el,
                url: link,
                selected: selected
              }
            );
          });
          this.initialImgs = this.dataResult.slice();
        }
      });
  }

  getBase64(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  /*
  * @desc change image action. save image throught php: item.action
  * @param item -> uploaded icon name
  * @return void
  */
  customImgReq = (item) => {
    // Create a FormData here to store files and other parameters.
    const ext = item.file.name.substr(item.file.name.lastIndexOf('.'));
    let filename = this.cd_prodotto + '-' + this.dataResult.length + '-' + new Date(Date.now()).getTime() + ext;
    const formData = new FormData();
    formData.append('file', item.file as any);
    formData.append('filename', filename);
    const req = new HttpRequest('POST', item.action!, formData, {
      reportProgress: true
    });

    return this.http.request(req).subscribe((event: HttpEvent<any>) => {
      if (event.type === HttpEventType.UploadProgress) {
        if (event.total! > 0) {
          (event as any).percent = (event.loaded / event.total!) * 100;
        }
        item.onProgress!(event, item.file!);
      } else if (event instanceof HttpResponse) {
        item.onSuccess!(event.body, item.file!, event);
        let link = this.g.imgPath + 'prodotti/' + filename;
        this.dataResult.push(
          {
            id: this.dataResult.length,
            name: filename,
            url: link,
            selected: true
          }
        );
      }
    },
      err => {
        item.onError!(err, item.file!);
      }
    );
  };

  selectImg(img) {
    img.selected = !img.selected;
  }

  async onClickSave() {
    let selectedImgs: string[] = [];
    let imgsName: string[] = [];
    let initImgsName: string[] = [];

    this.dataResult.forEach(img => {
      if (img.selected) {
        selectedImgs.push(img.name);
      }
      imgsName.push('"' + img.name + '"');
    });

    //INITIAL IMGS ARRAY
    this.initialImgs.forEach(img => {
      initImgsName.push('"' + img.name + '"');
    });

    let arrayString: string = '[' + imgsName.toString() + ']';
    let arrayStringInit: string = '[' + initImgsName.toString() + ']';
    const checkSumKey = await this.g.encryptDataGlobal(arrayString + this.cd_prodotto);
    const formData = new FormData();
    formData.append('cd', this.cd_prodotto);
    formData.append('images', arrayString);
    formData.append('initimages', arrayStringInit);
    formData.append('checkKey', checkSumKey);

    this.data.updateProdImages(formData).subscribe(
      result => {
        this.storage.setSelectedImages(selectedImgs);
        this.modal.triggerOk();
        this.modal.destroy();
      },
      error => {
        this.message.create('error', 'Errore', { nzDuration: 5000 });
        this.loading = false;
        console.error('GET TABLE: ', error);
      }
    );
  }

  deleteImg(i) {
    this.dataResult.splice(i, 1);
  }
}
