<nz-layout class="uiPageLayout">
  <nz-content class="uiPageContent uiAddContainer">
    <div class="uiProdDiv">
      <ng-container>
        <div nz-row [nzGutter]="16" class="uiProdRow">
          <div nz-col [nzSpan]="12" class="uiProdCol">
            <div class="uiInnerProdCol" *ngIf="product">
              <span>{{ 'Categoria' | translate }}</span>
              <br />
              <nz-select [(ngModel)]="selectedCat" *ngIf="categories" (ngModelChange)="catChanged($event)" style="width: 100%" #catSelect>
                <nz-option *ngFor="let cat of categories" [nzValue]="cat" [nzLabel]="cat.title"></nz-option>
              </nz-select>
            </div>
          </div>
          <div nz-col [nzSpan]="12" class="uiProdCol">
            <div class="uiInnerProdCol" *ngIf="product && selectedCat">
              <span>{{ 'Famiglia' | translate }}: {{ selectedFam }}</span>
              <br />
              <nz-select [(ngModel)]="selectedFam" style="width: 100%" (ngModelChange)="famChanged($event)" #famSelect>
                <nz-option *ngFor="let fam of selectedCat.children" [nzValue]="fam.key" [nzLabel]="fam.title"></nz-option>
              </nz-select>
            </div>
          </div>
        </div>
        <div nz-row [nzGutter]="16" class="uiProdRow" *ngIf="selectedFam">
          <div nz-col nzXXl="2" nzXl="2" nzLg="6" nzMd="6" nzSm="6" nzXs="6" class="uiProdCol">
            <div class="uiInnerProdCol" *ngIf="product">
              <span>{{ 'addCode' | translate }} </span>
              <br />
              <input nz-input [(ngModel)]="product.cd_prodotto" disabled />
            </div>
          </div>
          <div nz-col nzXXl="10" nzXl="10" nzLg="18" nzMd="18" nzSm="18" nzXs="18" class="uiProdCol">
            <div class="uiInnerProdCol" *ngIf="product">
              <span>{{ 'addProd' | translate }}</span> <br />
              <input nz-input [(ngModel)]="product.nome" />
            </div>
          </div>
          <div nz-col nzXXl="12" nzXl="12" nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="uiProdCol">
            <div class="uiInnerProdCol" *ngIf="product">
              <span>{{ 'addNoteInt' | translate }}</span> <br />
              <input nz-input [(ngModel)]="product.note_interne" />
            </div>
          </div>
        </div>
        <div nz-row [nzGutter]="16" class="uiProdRow" *ngIf="selectedFam">
          <div nz-col nzXXl="12" nzXl="12" nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="uiProdCol">
            <div class="uiInnerProdCol" *ngIf="product">
              <span>{{ 'addDesc' | translate }}</span> <br />
              <quill-editor [ngClass]="translate.currentLang === 'it' ? 'uiQuillIta' : ''" [styles]="{ height: '200px' }" [(ngModel)]="product.descrizione"></quill-editor>
            </div>
          </div>
          <div nz-col nzXXl="12" nzXl="12" nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="uiProdCol">
            <div class="uiInnerProdCol" *ngIf="product">
              <span>{{ 'addNote' | translate }}</span> <br />
              <quill-editor [ngClass]="translate.currentLang === 'it' ? 'uiQuillIta' : ''" [styles]="{ height: '200px' }" [(ngModel)]="product.note"></quill-editor>
            </div>
          </div>
        </div>
        <div nz-row [nzGutter]="16" class="uiProdRow" *ngIf="selectedFam">
          <div nz-col nzXXl="12" nzXl="12" nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="uiProdCol">
            <div class="uiInnerProdCol" *ngIf="product">
              <span class="uiProdColSpan">{{ 'addPrz' | translate }}</span> <br />
              <div>
                <div nz-row [nzGutter]="16" class="uiProdRow">
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiProdCol">
                    <span> </span> <br />
                    <div class="uiInnerColDiv">
                      <span>{{ 'addList' | translate }}</span>
                      <label nz-checkbox class="uiListCheckbox" (change)="enableList()"> </label>
                    </div>
                  </div>
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiInnerProdCol" *ngIf="product">
                    <span>{{ 'addListForn' | translate }}</span> <br />
                    <nz-input-group [nzSuffix]="suffixIconEuro">
                      <input type="number" nz-input nzPrecision="2" min="0" [(ngModel)]="product.pr_fornitore" [disabled]="!fornitore" (blur)="product.pr_fornitore = toFloat(product.pr_fornitore)" (change)="calculateMan($event); calculateMat($event)" />
                    </nz-input-group>
                    <ng-template #suffixIconEuro> € </ng-template>
                  </div>
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiInnerProdCol" *ngIf="product">
                    <span>{{ 'addScPerc' | translate }}</span> <br />
                    <nz-input-group [nzSuffix]="suffixIconPercent" class="uiSearchbar">
                      <input nz-input type="number" [(ngModel)]="product.sc_materiale" [disabled]="!fornitore" (ngModelChange)="calculateMat($event)" />
                    </nz-input-group>
                    <ng-template #suffixIconPercent> % </ng-template>
                  </div>
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiInnerProdCol" *ngIf="product">
                    <span>{{ 'addScExtra' | translate }}</span> <br />
                    <nz-input-group [nzSuffix]="suffixIconPercent" class="uiSearchbar">
                      <input nz-input type="number" [(ngModel)]="product.sc_extra" [disabled]="!fornitore" (ngModelChange)="calculateMat($event)" min="0" max="100" />
                    </nz-input-group>
                    <ng-template #suffixIconPercent> % </ng-template>
                  </div>
                </div>
                <div nz-row [nzGutter]="16" class="uiProdRow">
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiProdCol">
                    <span> </span> <br />
                    <div class="uiInnerColDiv">
                      <span>{{ 'addMat' | translate }}</span>
                    </div>
                  </div>
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiInnerProdCol" *ngIf="product">
                    <span>{{ 'addPrzUnit' | translate }}</span> <br />
                    <nz-input-group [nzSuffix]="suffixIconEuro" *ngIf="fornitore">
                      <input nz-input nzMin="0" type="number" nzPrecision="2" [ngModel]="product.unit_materiale | number: '1.2-2' | noComma" (ngModelChange)="product.unit_materiale = $event" disabled />
                    </nz-input-group>
                    <ng-template #suffixIconEuro> € </ng-template>
                    <nz-input-group [nzSuffix]="suffixIconEuro" *ngIf="!fornitore">
                      <input nz-input type="number" min="0" nzPrecision="2" [(ngModel)]="product.unit_materiale" (blur)="product.unit_materiale = toFloat(product.unit_materiale)" />
                    </nz-input-group>
                    <ng-template #suffixIconEuro> € </ng-template>
                  </div>
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiInnerProdCol" *ngIf="product">
                    <span> </span>
                    <input nz-input style="visibility: hidden" />
                  </div>
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiInnerProdCol" *ngIf="product">
                    <span>{{ 'addPrzTot' | translate }}</span> <br />
                    <nz-input-group [nzSuffix]="suffixIconEuro" class="uiTotalInput">
                      <input type="number" nz-input nzPrecision="2" value="{{ product.unit_materiale * product.quantita | number: '1.2-2' | noComma }}" />
                    </nz-input-group>
                    <ng-template #suffixIconEuro> € </ng-template>
                  </div>
                </div>
                <div nz-row [nzGutter]="16" class="uiProdRow">
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiProdCol">
                    <span> </span> <br />
                    <div class="uiInnerColDiv">
                      <span>{{ 'addMan' | translate }}</span>
                    </div>
                  </div>
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiInnerProdCol" *ngIf="product">
                    <span>{{ 'addPrzUnit' | translate }}</span> <br />
                    <nz-input-group [nzSuffix]="suffixIconEuro" *ngIf="fornitore">
                      <input type="number" nz-input nzPrecision="2" nzMin="0" [ngModel]="product.unit_manodopera | number: '1.2-2' | noComma" (ngModelChange)="product.unit_manodopera = $event" disabled />
                    </nz-input-group>
                    <ng-template #suffixIconEuro> € </ng-template>

                    <nz-input-group [nzSuffix]="suffixIconEuro" *ngIf="!fornitore">
                      <input type="number" nz-input nzPrecision="2" min="0" [(ngModel)]="product.unit_manodopera" (blur)="product.unit_manodopera = toFloat(product.unit_materiale)" />
                    </nz-input-group>
                    <ng-template #suffixIconEuro> € </ng-template>
                  </div>

                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiInnerProdCol" *ngIf="product">
                    <span>{{ 'addPosaPerc' | translate }}</span> <br />
                    <nz-input-group [nzSuffix]="suffixIconPercent" class="uiSearchbar">
                      <input nz-input type="number" [(ngModel)]="product.perc_manodopera" [disabled]="!fornitore" (ngModelChange)="calculateMan($event)" />
                    </nz-input-group>
                    <ng-template #suffixIconPercent> % </ng-template>
                  </div>
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiInnerProdCol" *ngIf="product">
                    <span>{{ 'addPrzTot' | translate }}</span> <br />
                    <nz-input-group [nzSuffix]="suffixIconEuro" class="uiTotalInput">
                      <input type="number" nz-input nzPrecision="2" value="{{ product.unit_manodopera * product.quantita | number: '1.2-2' | noComma }}" disabled />
                    </nz-input-group>
                    <ng-template #suffixIconEuro> € </ng-template>
                  </div>
                </div>
                <div nz-row [nzGutter]="16" class="uiProdRow">
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiProdCol">
                    <span> </span> <br />
                    <div class="uiInnerColDiv">
                      <span>{{ 'addC' | translate }}</span>
                    </div>
                  </div>
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiInnerProdCol" *ngIf="product">
                    <span>{{ 'addUnit' | translate }}</span> <br />
                    <nz-input-group [nzSuffix]="suffixIconEuro" class="uiTotalInput">
                      <input type="number" nz-input nzPrecision="2" value="{{ +product.unit_manodopera + +product.unit_materiale | number: '1.2-2' | noComma }}" disabled />
                    </nz-input-group>
                    <ng-template #suffixIconEuro> € </ng-template>
                  </div>
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiInnerProdCol" *ngIf="product">
                    <span>{{ 'addTot' | translate }}</span> <br />
                    <nz-input-group [nzSuffix]="suffixIconEuro" class="uiTotalInput">
                      <input type="number" nz-input nzPrecision="2" value="{{ product.unit_manodopera * product.quantita + product.unit_materiale * product.quantita | number: '1.2-2' | noComma }}" disabled />
                    </nz-input-group>
                    <ng-template #suffixIconEuro> € </ng-template>
                  </div>
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiInnerProdCol" *ngIf="product">
                    <span>{{ 'addRic' | translate }}</span> <br />
                    <input type="number" min="0.0" max="1" step="0.1" nz-input [(ngModel)]="product.ricarico" class="uiYellowInput" />
                  </div>
                </div>
                <div nz-row [nzGutter]="16" class="uiProdRow">
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiProdCol">
                    <span> </span> <br />
                    <div class="uiInnerColDiv">
                      <span>{{ 'addQum' | translate }}</span>
                    </div>
                  </div>
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiInnerProdCol" *ngIf="product">
                    <span>{{ 'addPrzVend' | translate }}</span> <br />

                    <nz-input-group [nzSuffix]="suffixIconEuro" *ngIf="product.ricarico > 0" class="uiTotalInput">
                      <input type="number" nz-input nzPrecision="2" value="{{ ((+product.unit_manodopera + +product.unit_materiale) * product.quantita) / product.ricarico | number: '1.2-2' | noComma }}" disabled />
                    </nz-input-group>
                    <ng-template #suffixIconEuro> € </ng-template>
                    <nz-input-group [nzSuffix]="suffixIconEuro" *ngIf="!product.ricarico || product.ricarico <= 0" class="uiTotalInput">
                      <input type="number" nz-input nzPrecision="2" value="{{ (+product.unit_manodopera + +product.unit_materiale) * product.quantita | number: '1.2-2' | noComma }}" disabled />
                    </nz-input-group>
                    <ng-template #suffixIconEuro> € </ng-template>
                  </div>
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiInnerProdCol" *ngIf="product">
                    <span>{{ 'addQ' | translate }}</span> <br />
                    <input nz-input type="number" min="0" [(ngModel)]="product.quantita" />
                  </div>
                  <div nz-col nzXXl="6" nzXl="6" nzLg="12" nzMd="12" nzSm="24" nzXs="24" class="uiInnerProdCol" *ngIf="product">
                    <span>{{ 'addUm' | translate }}</span> <br />
                    <nz-select [(ngModel)]="product.um" class="uiEditSelect">
                      <nz-option nzValue="cn" nzLabel="Cartone"></nz-option>
                      <nz-option nzValue="h" nzLabel="Ore"></nz-option>
                      <nz-option nzValue="hh" nzLabel="hh"></nz-option>
                      <nz-option nzValue="kg" nzLabel="Kilogrammi"></nz-option>
                      <nz-option nzValue="mc" nzLabel="Metri cubi"></nz-option>
                      <nz-option nzValue="ml" nzLabel="Metri lineari"></nz-option>
                      <nz-option nzValue="mn" nzLabel="Minuti"></nz-option>
                      <nz-option nzValue="mq" nzLabel="Metri quadrati"></nz-option>
                      <nz-option nzValue="mt" nzLabel="Metri"></nz-option>
                      <nz-option nzValue="nr" nzLabel="Numero"></nz-option>
                      <nz-option nzValue="pz" nzLabel="Pezzi"></nz-option>
                      <nz-option nzValue="vg" nzLabel="Verga"></nz-option>
                    </nz-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div nz-col nzXXl="12" nzXl="12" nzLg="24" nzMd="24" nzSm="24" nzXs="24" class="uiProdCol" *ngIf="product">
            <div class="uiInnerProdSwiperCol" *ngIf="product">
              <span class="uiProdColSpan">{{ 'addImgs' | translate }}</span>
              <button nz-button style="float: right; font-size: 12px; width: auto; height: auto; padding: 0; position: absolute; right: 7px; top: 24px; z-index: 1000" (click)="openImageModal()">
                <i class="fad fa-edit" style="font-size: 10px; padding: 5px"></i>
              </button>
              <br />
              <div class="uiProdSwiper" *ngIf="product">
                <swiper [slidesPerView]="1" [spaceBetween]="0" [pagination]="selectedImgs.length > 0" navigation="true">
                  <ng-template class="uiSwiperSlide" swiperSlide *ngFor="let slide of selectedImgs">
                    <img [src]="sanitize(g.imgPath + 'prodotti/' + slide)" />
                  </ng-template>
                </swiper>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </nz-content>
  <nz-footer class="uiPageFooter uiQuoteFooter">
    <button nz-button nzType="primary" nzSize="large" class="uiBackButton" (click)="back()">
      <i class="fad fa-angle-left"></i>
    </button>
    <button nz-button nzType="primary" nzSize="large" class="uiRightProd" (click)="saveInsert()">
      {{ 'save' | translate }}
      <i class="fad fad-swap-color fa-save"></i>
    </button>
  </nz-footer>
</nz-layout>
