import { Component, HostListener, OnInit, Pipe, PipeTransform, ViewChild, ViewContainerRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { TblTipoClienti } from "src/app/entities/TblTipoClienti";
import { TblVenditori } from "src/app/entities/TblVenditori";
import { DataService } from "src/app/services/data.service";
import { GlobalService } from "src/app/shared/global.service";
import { ContactModalComponent } from "./contactModal/contactModal.component";
import { Location } from "@angular/common";
import { StorageService } from "src/app/services/storage.service";
import { NzImageService } from "ng-zorro-antd/image";
import { NzMessageService } from "ng-zorro-antd/message";
import { AuthService } from "src/app/services/auth.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { NzUploadFile, UploadFilter } from "ng-zorro-antd/upload";
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import { color } from "html2canvas/dist/types/css/types/color";
import { from } from "rxjs";
import { json } from "stream/consumers";
import { group } from "console";

@Pipe({
  name: "safeUrl",
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }
}

@Component({
  selector: "app-quote",
  templateUrl: "./quote.component.html",
  styleUrls: ["./quote.component.scss"],
})
export class QuoteComponent implements OnInit {
  prevQta = 0;
  prevTot = 0;
  prevRic = 0;
  prevCost = 0;
  editHead = false;
  headForm: FormGroup;
  quoteId: string = "";
  allQuotes: any[];
  quoteContact: any;
  quoteGroups: any;
  selectQuote: any;
  quoteVersion: string;
  lastVersion: string;
  enableEdit = true;
  edit = false;
  editGroups = false;
  editCondition = false;
  editClauses = false;
  editCache: any;
  editCacheGroups: any;
  editCacheConditions: any;
  editCacheClauses: any;
  sellers: { cd_venditore: number; label: string; }[] = [];
  types: any[] = [];
  quoteProds: any[];
  quoteUnfilteredProds: any[];
  loading = true;
  sectSelect: any[] = [];
  listOfForniture: any[] = [];
  groups: any[];
  conditions: any[];
  clauses: any;
  activeDpd = false;
  selectedColor = "";
  selectedColorName = "";
  // *********************** 2023/07/04 Alberto Baggio ********************************************************
  groupTotal: { cost: number, total: number, option: string; }[] = [{ cost: 0, total: 0, option: '' }];
  // **********************************************************************************************************
  allegato: string = "";
  anagrafica: string = "";
  privacy: string = "";
  pdfDowload = false;

  disabled = false;

  paymentSettings: any[] = [];
  billingSettings: any[] = [];

  uploadFile: NzUploadFile = null;
  isUploading = false;
  isUploadingAnagrafica = false;
  isUploadingPrivacy = false;
  uploadUrl = this.g.phpPath + "/quotes/addCover.php";
  uploadUrlAnagrafica = this.g.phpPath + "/quotes/addAnagrafica.php";
  uploadUrlPrivacy = this.g.phpPath + "/quotes/addPrivacy.php";
  removeUrl = this.g.phpPath + "/quotes/deleteCover.php";
  removeUrlAnagrafica = this.g.phpPath + "/quotes/deleteAnagrafica.php";
  removeUrlPrivacy = this.g.phpPath + "/quotes/deletePrivacy.php";
  // ********************** 2023/07/10 Alberto Baggio *******************************************************
  standardCoverList: { folder: string, file: string, extension?: string, officialName?: string; }[] = [{ folder: '', file: 'Seleziona' }];
  standardCoverDisabled = false;
  selectedCover = this.standardCoverList[0];
  // **********************************************************************************************************

  // ********************* 2023/07/11 Alberto Baggio ************************************************************************
  myDateEnd: any;
  myDateStart: any;
  // *************************************************************************************************************************
  constructor(
    private g: GlobalService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private data: DataService,
    private modal: NzModalService,
    private message: NzMessageService,
    private viewContainerRef: ViewContainerRef,
    private location: Location,
    private router: Router,
    public storage: StorageService,
    private nzImageService: NzImageService,
    private auth: AuthService,
    private http: HttpClient
  ) { }

  @ViewChild("tplPreview") tplPreview;
  @ViewChild("tplPreview") tplPreviewAnagrafica;
  @ViewChild("tplPreview") tplPreviewPrivacy;

  @HostListener("window:beforeunload", ["$event"])
  unloadHandler(event: any) {
    event.preventDefault();
    return (event.returnValue = false);
  }

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      this.quoteId = param.id;
    });
    const formData = new FormData();
    formData.append("id", this.quoteId);

    this.data.getQuoteById(formData).subscribe(
      (result) => {
        console.log(result);
        this.allQuotes = result;
        this.selectQuote = result[0];

        // clean group error that have id 0
        result[0].groups = this.cleanOldGroupError(result[0]);

        this.quoteVersion = result[0].versione;
        this.lastVersion = result[0].versione;
        this.allegato = result[0].allegato;
        this.anagrafica = result[0].anagrafica;
        this.privacy = result[0].privacy;
        const storedQuote = this.storage.getSelectedQuote();
        if (storedQuote && storedQuote.contact) {
          this.quoteContact = storedQuote.contact;
        } else {
          this.quoteContact = result[0].contact as any;
        }
        if (storedQuote && storedQuote.contact) {
          this.groups = storedQuote.groups;
          this.conditions = storedQuote.conditions;
          this.clauses = storedQuote.clauses;
          this.selectQuote.preventivista = storedQuote.preventivista;
        } else {
          this.groups = JSON.parse(result[0].groups as any);
          this.conditions = JSON.parse(result[0].conditions);
          this.clauses = result[0].clauses;
        }

        this.quoteProds = result[0].rows as any[];
        this.g.actualPage = this.translate.instant("newQuote");
        this.updateEditCache();
        this.updateEditGroups();
        this.updateEditConditions();
        this.updateEditClauses();
        this.updateTotali(true);
        // *********************** 2023/07/04 Alberto Basggio ****************************************************************************************
        this.groups.forEach(group => {
          this.groupTotal.push({ cost: 0, total: 0, option: group.option });
        });

        this.quoteProds.forEach(quoteProd => {
          this.sumGroupTotal(quoteProd);
        });

        let tot = this.calculateConstAndPrev();
        this.prevCost = tot.prevCost;
        this.prevTot = tot.prevTot;
        // **********************************************************************************************************

        this.loading = false;
        let selectedQuote = {
          id: this.quoteId,
          version: this.quoteVersion,
          contact: this.quoteContact,
          total: this.prevTot,
          qta: this.prevQta,
          groups: this.groups,
          conditions: this.conditions,
          clauses: this.clauses,
          preventivista: this.selectQuote.preventivista,
        };
        this.storage.setSelectedQuote(selectedQuote);
        // *********************** 2023/07/04 Alberto Basggio ****************************************************************************************
        // this.groups.forEach((g) => {
        //   let rows = this.quoteProds.filter((p) => p.color.id === g.id);
        //   let total: number = 0;
        //   if (rows.length > 0) {
        //     rows.forEach((r, rindex) => {
        //       if (r.ricarico > 0) {
        //         total = total + ((r.unit_manodopera + r.unit_materiale) * r.quantita) / r.ricarico;
        //       } else {
        //         r.ricarico = 0;
        //         total = total + (r.unit_manodopera + r.unit_materiale) * r.quantita;
        //       }
        //       if (rindex === rows.length - 1) {
        //         this.groupTotal[g.id] = { total: total, option: g.option };
        //       }
        //     });
        //   } else {
        //     this.groupTotal[g.id] = { total: 0, option: g.option };
        //   }

        // });

        // **********************************************************************************************************
        this.reorderRows();
        this.quoteUnfilteredProds = [...this.quoteProds];

        if (this.selectQuote.stato === "Completato") {
          this.enableEdit = false;
        }
      },
      (error) => {
        console.error("Unable to get quote: ", error);
      }
    );

    this.data.getTable("settings").subscribe(
      (result) => {
        let settings = result;
        settings.forEach((set) => {
          // **************** 2023/07/26 Alberto Baggio ***********************************
          if (set.nome === "tipo_fatturazione") {
            this.billingSettings = set.valore.split('|');
          } else if (set.nome === "tipo_pagamento") {
            this.paymentSettings = set.valore.split('|');
          }
          // *******************************************************************************
        });
      },
      (error) => {
        this.loading = false;
        this.message.create("error", "Errore", { nzDuration: 5000 });
        console.error("GET TABLE: ", error);
      }
    );

    // ********************* 2023/07/11 Albero Baggio **********************************************************************************
    this.data.getStandardCover().subscribe((data) => {
      data.map(cover => {
        cover.extension = cover.file.substr(cover.file.lastIndexOf("."));
        cover.officialName = this.quoteId + "-allegato" + cover.extension;
        cover.file = cover.file.replace(cover.extension, '');
      });

      this.standardCoverList = [...this.standardCoverList, ...data];
    });
    // *********************************************************************************************************************************
  }

  // ##############################
  // FUNCTION: update totali
  updateTotali(tryParse: boolean = false): void {
    this.prevQta = this.prevCost = this.prevTot = 0;

    this.quoteProds.forEach((row) => {
      this.prevQta = this.prevQta + row.quantita;

      // ******************** 2023/07/05 Alberto Baggio *************************************************************************
      // this.prevCost = this.prevCost + (row.unit_manodopera + row.unit_materiale) * row.quantita;
      // if (row.ricarico > 0) {
      //   this.prevTot = this.prevTot + ((row.unit_manodopera + row.unit_materiale) * row.quantita) / row.ricarico;
      // } else if (!row.ricarico || row.ricarico <= 0) {
      //   this.prevTot = this.prevTot + (row.unit_manodopera + row.unit_materiale) * row.quantita;
      // }
      // ***********************************************************************************************************************
      if (tryParse) {
        row.immagini = this.tryParseJSONObject(row.immagini);
        row.color = this.tryParseJSONObject(row.color);
      }
    });
    // ******************** 2023/07/05 Alberto Baggio *************************************************************************
    let tot = this.calculateConstAndPrev();
    this.prevCost = tot.prevCost;
    this.prevTot = tot.prevTot;
    // ****************************************************************************************************************************
  }

  // ##############################
  // UPDATE TOTALI
  updateEditCache(): void {
    this.editCache = JSON.parse(JSON.stringify(this.quoteContact));
    this.editCache.data_preventivo = this.editCache.data_preventivo * 1000;
  }

  updateEditGroups(): void {
    this.editCacheGroups = JSON.parse(JSON.stringify(this.groups));
  }

  updateEditConditions(): void {
    this.editCacheConditions = JSON.parse(JSON.stringify(this.conditions));
  }

  updateEditClauses(): void {
    this.editCacheClauses = JSON.parse(JSON.stringify(this.clauses));
  }

  startEdit(): void {
    this.data.getTable("sellers").subscribe(
      (result) => {
        const sellersArray = result as TblVenditori[];
        sellersArray.forEach((s) => {
          this.sellers.push({
            cd_venditore: s.cd_venditore,
            label: s.cd_venditore + ": " + s.nome + " " + s.cognome,
          });
        });
      },
      (error) => {
        console.error("GET TABLE: ", error);
      }
    );
    this.data.getTable("type").subscribe(
      (result) => {
        const typesArray = result as TblTipoClienti[];
        typesArray.forEach((s) => {
          this.types.push(s.nome);
          this.sectSelect.push(s.nome);
        });
      },
      (error) => {
        console.error("GET TABLE: ", error);
      }
    );

    //this.sectSelect = ["OFFICE", "MEDICAL", "HOTEL", "HOME", "ALTRO"];
    this.listOfForniture = ["ARREDO", "EDILE (CTG)", "BOX", "CHIAVI IN MANO"];
    this.edit = true;
    this.editCache = JSON.parse(JSON.stringify(this.quoteContact));
    this.editCache.data_preventivo = this.editCache.data_preventivo * 1000;
  }

  cancelEdit(): void {
    this.editCache = this.quoteContact;
    this.edit = false;
  }

  async saveEdit() {
    this.quoteContact = this.editCache;

    if (this.editCache.data_preventivo) {
      this.quoteContact.data_preventivo = ((this.editCache.data_preventivo as any) / 1000).toFixed(0);
    }
    if (this.quoteContact.cd_venditore) {
      this.quoteContact.cVend = this.sellers.find((vend) => vend.cd_venditore == this.quoteContact.cd_venditore).label;
    }

    // *************** 2023/07/05 Alberto Baggio ***********************************************************************
    let tot = this.calculateConstAndPrev();
    this.prevCost = tot.prevCost;
    this.prevTot = tot.prevTot;
    // *****************************************************************************************************************

    this.updateEditCache();
    this.edit = false;
    let selectedQuote = {
      id: this.quoteId,
      version: this.quoteVersion,
      contact: this.quoteContact,
      total: this.prevTot,
      qta: this.prevQta,
      groups: this.groups,
      conditions: this.conditions,
      clauses: this.clauses,
      preventivista: this.selectQuote.preventivista,
    };
    this.storage.setSelectedQuote(selectedQuote);
  }

  addContact() {
    const modal = this.modal.create({
      nzTitle: "Scegli contatto",
      nzContent: ContactModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzClassName: "uiContactModal",
      nzFooter: null,
      nzOnOk: () => {
        if (
          this.quoteContact.cAtt ||
          this.quoteContact.cAttEmail !== null ||
          this.quoteContact.cCap !== null ||
          this.quoteContact.cCf !== null ||
          this.quoteContact.cGr !== null ||
          this.quoteContact.cInd !== null ||
          this.quoteContact.cLoc !== null ||
          this.quoteContact.cPi !== null ||
          this.quoteContact.cPv !== null ||
          this.quoteContact.cRagSoc !== null ||
          this.quoteContact.cVend
        ) {
          this.modal.confirm({
            nzTitle: "Vuoi sovrascrivere i dati esistenti?",
            nzOnOk: () => {
              const newContact = this.data.getContact();
              this.quoteContact.cd_cf = newContact.cd_cf;
              this.quoteContact.cd_venditore = newContact.cd_venditore;
              this.quoteContact.ragSoc = newContact.ragSoc;
              this.quoteContact.cVend = newContact.cVend;
              this.quoteContact.indirizzo = newContact.indirizzo;
              this.quoteContact.cap = newContact.cap;
              this.quoteContact.localita = newContact.localita;
              this.quoteContact.cd_provincia = newContact.cd_provincia;
              this.quoteContact.codicefiscale = newContact.codicefiscale;
              this.quoteContact.partitaiva = newContact.partitaiva;
              this.quoteContact.attne = newContact.attne;
              this.quoteContact.email = newContact.email;
              this.quoteContact.tipologia = newContact.tipologia;
              this.quoteContact.id_contatto = newContact.id_contatto;

              // *************** 2023/07/05 Alberto Baggio ********************************************************************
              let tot = this.calculateConstAndPrev();
              this.prevCost = tot.prevCost;
              this.prevTot = tot.prevTot;
              //  ***************************************************************************************************************
              let selectedQuote = {
                id: this.quoteId,
                version: this.quoteVersion,
                contact: this.quoteContact,
                total: this.prevTot,
                qta: this.prevQta,
                groups: this.groups,
                conditions: this.conditions,
                clauses: this.clauses,
              };
              this.storage.setSelectedQuote(selectedQuote);
            },
            nzOnCancel: () => {
              this.data.saveContact("");
            },
          });
        } else {
          const newContact = this.data.getContact();
          this.quoteContact.cd_cf = newContact.cd_cf;
          this.quoteContact.cd_venditore = newContact.cd_venditore;
          this.quoteContact.ragSoc = newContact.ragSoc;
          this.quoteContact.cVend = newContact.cVend;
          this.quoteContact.indirizzo = newContact.indirizzo;
          this.quoteContact.cap = newContact.cap;
          this.quoteContact.localita = newContact.localita;
          this.quoteContact.cd_provincia = newContact.cd_provincia;
          this.quoteContact.codicefiscale = newContact.codicefiscale;
          this.quoteContact.partitaiva = newContact.partitaiva;
          this.quoteContact.attne = newContact.attne;
          this.quoteContact.email = newContact.email;
          this.quoteContact.tipologia = newContact.tipologia;
          this.quoteContact.id_contatto = newContact.id_contatto;

          // *************** 2023/07/05 Alberto Baggio ********************************************************************
          let tot = this.calculateConstAndPrev();
          this.prevCost = tot.prevCost;
          this.prevTot = tot.prevTot;
          // ************************************************************************************************************

          let selectedQuote = {
            id: this.quoteId,
            version: this.quoteVersion,
            contact: this.quoteContact,
            total: this.prevTot,
            qta: this.prevQta,
            groups: this.groups,
            conditions: this.conditions,
            clauses: this.clauses,
          };
          this.storage.setSelectedQuote(selectedQuote);
        }
      },
    });
  }

  back() {
    this.storage.removeSelectedQuote();
    this.location.back();
  }

  tryParseJSONObject(jsonString) {
    try {
      var o = JSON.parse(jsonString);
      if (o && typeof o === "object") {
        return o;
      }
    } catch (e) { }

    return jsonString;
  }

  onChangeVersion(e) {
    this.quoteProds.forEach((row) => {
      row.immagini = JSON.stringify(row.immagini);
    });

    this.edit = false;
    this.quoteContact = e.contact;
    this.quoteVersion = e.versione;
    this.quoteProds = e.rows;
    this.updateTotali(true);

    this.g.actualPage = this.translate.instant("quote") + " " + this.quoteId + " v" + this.quoteVersion;
    if (e.stato !== "Completato") {
      this.enableEdit = true;
    } else {
      this.enableEdit = false;
    }

    // ***************************** 2023/10/05 AB *********************************************************************************

    this.resetData();
    // rebuild nginit with new version
    let result = [e];

    this.rebuild(result);
    // *********************************************************************************************************************************
  }

  addProd() {
    this.router.navigateByUrl("/quote/" + this.quoteId + "/add");
  }

  filter(searchValue: string) {
    this.quoteProds = this.quoteUnfilteredProds.filter(
      (item: any) => item.nome.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );
  }

  showImages(cd): void {
    let i = this.quoteProds.findIndex((data) => data.cd_riga === cd);
    const row = this.quoteProds[i].immagini;
    const images = [];
    console.log(this.selectQuote.cd_preventivo);
    row.forEach((img) => {
      images.push({
        // ******************* 2023/07/19 Alberto Baggio *******************************************************************
        src: img.startsWith(this.selectQuote.cd_preventivo) ? this.g.imgPath + `preventivi/${this.selectQuote.cd_preventivo}/` + img : this.g.imgPath + 'prodotti/' + img,
        // ***********************************************************************************************************
      });
    });
    this.nzImageService.preview(images, { nzZoom: 1.5, nzRotate: 0 });
  };

  showDescription(cd): void {
    let i = this.quoteProds.findIndex((data) => data.cd_riga === cd);
    this.modal.create({
      nzContent: this.quoteProds[i].descrizione,
      nzClosable: true,
      nzFooter: null,
      nzOnOk: () => { },
    });
  };

  showNote(cd): void {
    let i = this.quoteProds.findIndex((data) => data.cd_riga === cd);
    this.modal.create({
      nzContent: this.quoteProds[i].note,
      nzClosable: true,
      nzFooter: null,
      nzOnOk: () => { },
    });
  };

  addQ(cd, q) {
    let i = this.quoteProds.findIndex((data) => data.cd_riga === cd);
    if (this.quoteProds[i].quantita + q >= 0) {
      this.quoteProds[i].quantita = Math.round((parseFloat(this.quoteProds[i].quantita) + parseFloat(q)) * 100) / 100;
      this.prevQta = Math.round((this.prevQta + parseFloat(q)) * 100) / 100;

      // ******************** 2023/07/05 Alberto Baggio *************************************************************************
      // this.prevCost = this.prevCost + (this.quoteProds[i].unit_manodopera + this.quoteProds[i].unit_materiale) * q;
      // if (this.quoteProds[i].ricarico > 0) {
      //   this.prevTot =
      //     this.prevTot +
      //     ((this.quoteProds[i].unit_manodopera + this.quoteProds[i].unit_materiale) * q) / this.quoteProds[i].ricarico;
      // } else if (!this.quoteProds[i].ricarico || this.quoteProds[i].ricarico <= 0) {
      //   this.prevTot = this.prevTot + (this.quoteProds[i].unit_manodopera + this.quoteProds[i].unit_materiale) * q;
      // }

      let tot = this.calculateConstAndPrev();
      this.prevCost = tot.prevCost;
      this.prevTot = tot.prevTot;
      // *****************************************************************************************************************************
    }
    this.updateQuoteRow(i);
  }

  async deleteQuoteRow(cd) {
    let i = this.quoteProds.findIndex((data) => data.cd_riga === cd);
    const checkSumKey = await this.g.encryptDataGlobal("TblRighePreventivo" + cd);
    const formData = new FormData();
    formData.append("cd", cd);
    formData.append("checkKey", checkSumKey);
    console.log(cd);
    this.data.deleteQuoteRow(formData).subscribe(
      (result) => {
        this.prevQta -= this.quoteProds[i].quantita;

        // ******************** 2023/07/05 Alberto Baggio *************************************************************************
        // this.prevCost =
        //   this.prevCost -
        //   (this.quoteProds[i].unit_manodopera + this.quoteProds[i].unit_materiale) * this.quoteProds[i].quantita;
        // if (this.quoteProds[i].ricarico > 0) {
        //   this.prevTot =
        //     this.prevTot -
        //     ((this.quoteProds[i].unit_manodopera + this.quoteProds[i].unit_materiale) * this.quoteProds[i].quantita) /
        //     this.quoteProds[i].ricarico;
        // } else if (!this.quoteProds[i].ricarico || this.quoteProds[i].ricarico <= 0) {
        //   this.prevTot =
        //     this.prevTot -
        //     (this.quoteProds[i].unit_manodopera + this.quoteProds[i].unit_materiale) * this.quoteProds[i].quantita;
        // }

        let tot = this.calculateConstAndPrev();
        this.prevCost = tot.prevCost;
        this.prevTot = tot.prevTot;
        // ***************************************************************************************************************************

        this.quoteProds = this.quoteProds.filter((d) => d.cd_riga !== cd);
        this.reorderRows();
      },
      (error) => {
        this.message.create("error", "Errore", { nzDuration: 5000 });
        this.loading = false;
        console.error("GET TABLE: ", error);
      }
    );
  }

  async previewPdf() {
    this.pdfDowload = true;
    // ****************** 2023/07/18 Alberto Baggio *****************************************************************************
    this.saveQuote(false);
    // ************************************************************************************************************************
    const formData = new FormData();
    formData.append("cd", this.selectQuote.cd_preventivo);
    formData.append("versione", this.selectQuote.versione);


    this.data.generatePdf(formData).subscribe(
      (result) => {
        window.open(this.g.pdfPath + result.nome, "_blank");
        this.pdfDowload = false;
      },
      (error) => {
        console.error("Unable to display quote: ", error);
        this.message.create("error", "Errore", { nzDuration: 5000 });
        this.pdfDowload = false;
      }
    );
  }

  async saveQuote(redirectPage) {
    let insert = {
      cd_preventivo: this.quoteId,
      versione: this.quoteVersion,
      cd_cf: this.quoteContact.cd_cf,
      note: this.quoteContact.note,
      // ********************* 2023/07/12 Alberto Baggio ************************************************************************
      commessa: +this.quoteContact.commessa,
      // ***********************************************************************************************************************
      data_autore: this.g.loggedUser.nome + " " + this.g.loggedUser.cognome,
      data_modifica: (new Date(Date.now()).getTime() / 1000).toFixed(0),
      data_conferma: null,
      tipologia: this.quoteContact.tipologia ? this.quoteContact.tipologia : "",
      fornitura: this.quoteContact.fornitura,
      cd_venditore: this.quoteContact.cd_venditore ? this.quoteContact.cd_venditore : "",
      id_contatto: this.quoteContact.id_contatto ? this.quoteContact.id_contatto : "",
      data_preventivo: this.quoteContact.data_preventivo,
      stato: "In corso",
      ragSoc: this.quoteContact.ragSoc,
      indirizzo: this.quoteContact.indirizzo,
      cap: this.quoteContact.cap,
      localita: this.quoteContact.localita,
      cd_provincia: this.quoteContact.cd_provincia,
      codicefiscale: this.quoteContact.codicefiscale,
      partitaiva: this.quoteContact.partitaiva,
      attne: this.quoteContact.attne,
      email: this.quoteContact.email,
      groups: JSON.stringify(this.groups),
      conditions: JSON.stringify(this.conditions),
      clauses: this.clauses,
      preventivista: this.selectQuote.preventivista,
    };
    const jsonInsert = JSON.stringify(insert);

    const checkSumKey = await this.g.encryptDataGlobal("TblPreventivi" + jsonInsert);
    const formData = new FormData();
    formData.append("insert", jsonInsert);
    formData.append("checkKey", checkSumKey);

    this.data.saveQuote(formData).subscribe(
      (result) => {
        this.storage.removeSelectedQuote();
        if (redirectPage) {
          this.router.navigateByUrl("/history", {});
        }
      },
      (error) => {
        this.message.create("error", "Errore", { nzDuration: 5000 });
        console.error("GET TABLE: ", error);
      }
    );
  }

  async completeQuote() {
    let insert = {
      cd_preventivo: this.quoteId,
      versione: this.quoteVersion,
      cd_cf: this.quoteContact.cd_cf,
      note: this.quoteContact.note,
      // ********************* 2023/07/12 Alberto Baggio ************************************************************************
      commessa: +this.quoteContact.commessa,
      // *************************************************************************************************************************
      data_autore: this.g.loggedUser.nome + " " + this.g.loggedUser.cognome,
      data_modifica: (new Date(Date.now()).getTime() / 1000).toFixed(0),
      data_conferma: (new Date(Date.now()).getTime() / 1000).toFixed(0),
      tipologia: this.quoteContact.tipologia,
      fornitura: this.quoteContact.fornitura,
      cd_venditore: this.quoteContact.cd_venditore ? this.quoteContact.cd_venditore : "",
      id_contatto: this.quoteContact.id_contatto ? this.quoteContact.id_contatto : "",
      data_preventivo: this.quoteContact.data_preventivo,
      stato: "Completato",
      ragSoc: this.quoteContact.ragSoc,
      indirizzo: this.quoteContact.indirizzo,
      cap: this.quoteContact.cap,
      localita: this.quoteContact.localita,
      cd_provincia: this.quoteContact.cd_provincia,
      codicefiscale: this.quoteContact.codicefiscale,
      partitaiva: this.quoteContact.partitaiva,
      attne: this.quoteContact.attne,
      email: this.quoteContact.email,
      groups: JSON.stringify(this.groups),
      conditions: JSON.stringify(this.conditions),
      clauses: this.clauses,
      preventivista: this.selectQuote.preventivista,
    };
    const jsonInsert = JSON.stringify(insert);

    const checkSumKey = await this.g.encryptDataGlobal("TblPreventivi" + jsonInsert);
    const formData = new FormData();
    formData.append("insert", jsonInsert);
    formData.append("checkKey", checkSumKey);

    this.data.saveQuote(formData).subscribe(
      (result) => {
        this.storage.removeSelectedQuote();
        this.location.back();
      },
      (error) => {
        this.message.create("error", "Errore", { nzDuration: 5000 });
        console.error("GET TABLE: ", error);
      }
    );
  }

  onChangeDate(e) {
    this.editCache.data_preventivo = e;
  }

  async editQuoteRow(cd) {

    // *************** 2023/07/05 Alberto Baggio ********************************************************************
    let tot = this.calculateConstAndPrev();
    this.prevCost = tot.prevCost;
    this.prevTot = tot.prevTot;
    // ***************************************************************************************************************

    let i = this.quoteProds.findIndex((data) => data.cd_riga === cd);

    // *************** 2023/07/07 Alberto Baggio ********************************************************************
    if ((this.quoteProds[i].color.option !== 'H') || (this.quoteProds[i].option !== '')) {
      if (this.quoteProds[i].ricarico > 0) {
        this.prevTot = this.prevTot - ((this.quoteProds[i].unit_manodopera + this.quoteProds[i].unit_materiale) * this.quoteProds[i].quantita) / this.quoteProds[i].ricarico;
      } else {
        this.prevTot = this.prevTot - ((this.quoteProds[i].unit_manodopera + this.quoteProds[i].unit_materiale) * this.quoteProds[i].quantita);
      }
    }
    // ***************************************************************************************************************

    let selectedQuote = {
      id: this.quoteId,
      version: this.quoteVersion,
      contact: this.quoteContact,
      total: this.prevTot,
      qta: this.prevQta - this.quoteProds[i].quantita,
      groups: this.groups,
      conditions: this.conditions,
      clauses: this.clauses,
      preventivista: this.selectQuote.preventivista,
    };
    this.storage.setSelectedQuote(selectedQuote);
    this.router.navigateByUrl("/quote/" + this.quoteId + "/edit/" + cd);
  }

  async deleteQuote() {
    const checkSumKey = await this.g.encryptDataGlobal(
      "TblPreventivi" + this.auth.getUser().cd_utente + this.auth.getUser().email
    );
    const formData = new FormData();
    formData.append("cf", this.auth.getUser().cd_utente);
    formData.append("email", this.auth.getUser().email);
    formData.append("cd", this.quoteId);
    formData.append("versione", this.quoteVersion);
    formData.append("checkKey", checkSumKey);

    this.data.deleteQuoteVersion(formData).subscribe(
      (result) => {
        this.back();
      },
      (error) => {
        console.error("Unable to get last: ", error);
      }
    );
  }

  toggleDropdown() {
    this.activeDpd = !this.activeDpd;
  }

  startGroupEdit(): void {
    this.editGroups = true;
  };

  cancelGroupEdit(): void {
    this.editGroups = false;
    this.editCacheGroups = this.groups;
  }

  async saveGroupEdit() {
    let prev = this.storage.getSelectedQuote();
    this.groups = this.editCacheGroups;
    this.updateEditGroups();
    this.editGroups = false;

    let selectedQuote = {
      id: this.quoteId,
      version: this.quoteVersion,
      contact: this.quoteContact,
      total: this.prevTot,
      qta: this.prevQta,
      // ********************* 2023/07/11 Alberto Baggio ************************************************************************
      groups: [{ "id": 0, "class": "uiTransparentDot uiColorGroup", "name": "", "option": "" }, ...this.groups],
      // ***********************************************************************************************************************
      conditions: this.conditions,
      clauses: this.clauses,
      preventivista: this.selectQuote.preventivista,
    };
    this.storage.setSelectedQuote(selectedQuote);

    const formData = new FormData();
    formData.append("prev", JSON.stringify(prev.groups));
    formData.append("next", JSON.stringify(selectedQuote.groups));
    formData.append("id", this.quoteId);
    formData.append("version", this.quoteVersion);

    this.data.updateRowColor(formData).subscribe(
      (result) => {
        // ******************** 2023/07/05 Alberto Baggio *************************************************************************
        this.quoteProds.forEach((quoteProd, index) => {
          if (JSON.stringify(quoteProd.color) != JSON.stringify(result[index].color)) {
            if (result[index].color.option === '') {
              this.substractGroupTotal(quoteProd, quoteProd.color);
            } else if (quoteProd.color.option === '' && result[index].color.option !== '') {
              this.sumGroupTotal(quoteProd);
            }
            quoteProd.color = result[index].color;
            this.groupTotal[quoteProd.color.id].option = quoteProd.color.option;
            this.reorderRows();
          }
        });
        let tot = this.calculateConstAndPrev();
        this.prevCost = tot.prevCost;
        this.prevTot = tot.prevTot;

        let selectedQuote = {
          id: this.quoteId,
          version: this.quoteVersion,
          contact: this.quoteContact,
          total: this.prevTot,
          qta: this.prevQta,
          groups: this.groups,
          conditions: this.conditions,
          clauses: this.clauses,
          preventivista: this.selectQuote.preventivista,
        };
        this.storage.setSelectedQuote(selectedQuote);
        // ***************************************************************************************************************************
      },
      (error) => {
        console.error("Unable to get last: ", error);
      }
    );

  }

  setRowColor(color, riga) {
    let i = this.quoteProds.findIndex((data) => data.cd_riga === riga);
    let prevColor = this.quoteProds[i].color;
    // ******************** 2023/07/05 Alberto Baggio *************************************************************************
    if (color === "null" || color.option === 'H' || color.option === 'I') {
      if (color === 'null') {
        this.quoteProds[i].color = JSON.parse('{"id":0,"class":"uiTransparentDot uiColorGroup","name":"","option":""}');
      } else if (color.option === 'H' || color.option === 'I') {
        this.quoteProds[i].color = color;
        this.substractGroupTotal(this.quoteProds[i], prevColor);
      }
      // if (this.quoteProds[i].ricarico > 0) {
      //   this.groupTotal[prevColor.id].total =
      //     this.groupTotal[prevColor.id].total -
      //     ((this.quoteProds[i].unit_manodopera + this.quoteProds[i].unit_materiale) * this.quoteProds[i].quantita) /
      //     this.quoteProds[i].ricarico;
      // } else {
      //   this.groupTotal[prevColor.id].total =
      //     this.groupTotal[prevColor.id].total -
      //     (this.quoteProds[i].unit_manodopera + this.quoteProds[i].unit_materiale) * this.quoteProds[i].quantita;

    } else if (color !== "null" || color.id !== prevColor.id) {
      this.substractGroupTotal(this.quoteProds[i], prevColor);
      this.quoteProds[i].color = color;
      this.sumGroupTotal(this.quoteProds[i]);
      // if (this.quoteProds[i].ricarico > 0) {
      //   this.groupTotal[color.id].total =
      //     this.groupTotal[color.id].total +
      //     ((this.quoteProds[i].unit_manodopera + this.quoteProds[i].unit_materiale) * this.quoteProds[i].quantita) /
      //     this.quoteProds[i].ricarico;
      // } else {
      //   this.groupTotal[color.id].total =
      //     this.groupTotal[color.id].total +
      //     (this.quoteProds[i].unit_manodopera + this.quoteProds[i].unit_materiale) * this.quoteProds[i].quantita;
      // }
    }


    if (prevColor.option === 'H' || color.option === 'H') {
      const element = this.quoteProds.splice(i, 1);
      this.quoteProds.push(element[0]);
      i = this.quoteProds.length - 1;
    }

    let tot = this.calculateConstAndPrev();
    this.prevCost = tot.prevCost;
    this.prevTot = tot.prevTot;
    this.updateQuoteRow(i);
    this.reorderRows();
    // ******************************************************************************************************************************
  }

  async updateQuoteRow(i) {
    let insert = {
      cd_riga: this.quoteProds[i].cd_riga,
      quantita: this.quoteProds[i].quantita,
      ricarico: this.quoteProds[i].ricarico,
      color: JSON.stringify(this.quoteProds[i].color),
    };

    const jsonInsert = JSON.stringify(insert);
    const checkSumKey = await this.g.encryptDataGlobal("TblPreventivi" + jsonInsert);
    const formData = new FormData();
    formData.append("insert", jsonInsert);
    formData.append("checkKey", checkSumKey);

    this.data.updateQuoteRow(formData).subscribe(
      (result) => {
        this.resetData();
        this.rebuild([this.selectQuote]);
      },
      (error) => {
        this.message.create("error", "Errore", { nzDuration: 5000 });
        console.error("GET TABLE: ", error);
      }
    );
  }

  startConditionEdit(): void {
    this.editCondition = true;
  };

  onDateCondition(e, c) {
    // ********************* 2023/07/11 Alberto Baggio ************************************************************************
    if (!this.myDateStart && !this.myDateEnd) return;
    c.option = e.toLocaleDateString('en-GB');
    setTimeout(() => {
      this.myDateStart = null;
      this.myDateEnd = null;
    }, 500);
    // *************************************************************************************************************************
  }

  cancelConditionEdit(): void {
    this.editCondition = false;
    this.editCacheConditions = this.conditions;
  }

  async saveConditionsEdit() {
    this.conditions = this.editCacheConditions;
    this.updateEditConditions();
    this.editCondition = false;

    // *************** 2023/07/05 Alberto Baggio ********************************************************************
    let tot = this.calculateConstAndPrev();
    this.prevCost = tot.prevCost;
    this.prevTot = tot.prevTot;
    // *********************************************************************************************************
    let selectedQuote = {
      id: this.quoteId,
      version: this.quoteVersion,
      contact: this.quoteContact,
      total: this.prevTot,
      qta: this.prevQta,
      groups: this.groups,
      conditions: this.conditions,
      clauses: this.clauses,
      preventivista: this.selectQuote.preventivista,
    };
    this.storage.setSelectedQuote(selectedQuote);
  }

  startClausesEdit(): void {
    this.editClauses = true;
  };

  cancelClausesEdit(): void {
    this.editClauses = false;
    this.editCacheClauses = this.clauses;
  }

  async saveClausesEdit() {
    this.clauses = this.editCacheClauses;
    this.updateEditClauses();
    this.editClauses = false;

    // *************** 2023/07/05 Alberto Baggio ********************************************************************
    let tot = this.calculateConstAndPrev();
    this.prevCost = tot.prevCost;
    this.prevTot = tot.prevTot;
    // **************************************************************************************************************

    let selectedQuote = {
      id: this.quoteId,
      version: this.quoteVersion,
      contact: this.quoteContact,
      total: this.prevTot,
      qta: this.prevQta,
      groups: this.groups,
      conditions: this.conditions,
      clauses: this.clauses,
      preventivista: this.selectQuote.preventivista,
    };
    this.storage.setSelectedQuote(selectedQuote);
  }

  //DEPRECATE
  saveTableIndex(e) {
    this.storage.setQuoteTableIndex(e);
  }
  //DEPRECATE
  getQuoteTableIndex() {
    let index = this.storage.getQuoteTableIndex();
    if (index === "add") {
      index = Math.ceil(this.quoteProds.length / 3);
      this.storage.setQuoteTableIndex(Math.ceil(this.quoteProds.length / 3));
    }
    return index;
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.quoteProds, event.previousIndex, event.currentIndex);
      this.reorderRows();
    }
  }
  reorderRows() {
    // ******************************* 2023/07/2023 Alberto Baggio ******************************************************************************
    let opzionalIndex = 900;
    let regularIndex = 0;
    this.quoteProds.forEach((e: any, index: any) => {
      if (e.color.option === 'H') {
        opzionalIndex++;
        e.ordine = opzionalIndex;
      } else {
        regularIndex++;
        e.ordine = regularIndex;
      }
      // ***************************************************************************************************************************************
      const formData = new FormData();
      formData.append("cd", e.cd_riga);
      formData.append("order", e.ordine);
      this.data.editRowOrder(formData).subscribe(
        (result) => { },
        (error) => {
          this.message.create("error", "Errore", { nzDuration: 5000 });
          console.error("SET ORDER: ", error);
        }
      );
    });
    this.quoteProds = [...this.quoteProds];
  }

  async copyQuoteRow(cd) {
    const formData = new FormData();
    formData.append("cd", cd);

    this.data.getRowById(formData).subscribe(
      async (result) => {
        let originalRow = result[0];
        let newRow = {
          cd_riga: null,
          cd_preventivo: originalRow.cd_preventivo,
          versione: originalRow.versione,
          cd_prodotto: originalRow.cd_prodotto,
          nome: originalRow.nome,
          descrizione: originalRow.descrizione,
          cd_fam: originalRow.cd_fam,
          note: originalRow.note,
          note_interne: originalRow.note_interne,
          um: originalRow.um,
          quantita: originalRow.quantita,
          perc_manodopera: originalRow.perc_manodopera,
          sc_materiale: originalRow.sc_materiale,
          sc_extra: originalRow.sc_extra,
          unit_manodopera: originalRow.unit_manodopera,
          unit_materiale: originalRow.unit_materiale,
          pr_fornitore: originalRow.pr_fornitore,
          ricarico: originalRow.ricarico,
          immagini: JSON.parse(originalRow.immagini),
          color: JSON.parse(originalRow.color),
        };
        const jsonInsert = JSON.stringify(newRow);

        const checkSumKey = await this.g.encryptDataGlobal("TblPreventivi" + jsonInsert);
        const formData2 = new FormData();
        formData2.append("insert", jsonInsert);
        formData2.append("checkKey", checkSumKey);

        this.data.addQuoteRow(formData2).subscribe(
          (result) => {
            newRow.cd_riga = `${result}`;
            this.quoteProds = [...this.quoteProds, newRow];
            this.prevQta = this.prevQta + newRow.quantita;
            // ******************** 2023/07/05 Alberto Baggio *************************************************************************
            // this.prevCost = this.prevCost + (newRow.unit_manodopera + newRow.unit_materiale) * newRow.quantita;
            // if (newRow.ricarico > 0) {
            //   this.prevTot =
            //     this.prevTot + ((newRow.unit_manodopera + newRow.unit_materiale) * newRow.quantita) / newRow.ricarico;
            // } else if (!newRow.ricarico || newRow.ricarico <= 0) {
            //   this.prevTot = this.prevTot + (newRow.unit_manodopera + newRow.unit_materiale) * newRow.quantita;
            // }
            // let tot = this.calculateConstAndPrev();
            // this.prevCost = tot.prevCost;
            // this.prevTot = tot.prevTot;
            // ****************************************************************************************************************************


            this.selectQuote.rows.push(newRow);

            this.resetData();

            this.rebuild([this.selectQuote]);




            this.reorderRows();
          },
          (error) => {
            this.message.create("error", "Errore", { nzDuration: 5000 });
            console.error("ADD ROW: ", error);
          }
        );
      },
      (error) => {
        this.message.create("error", "Errore", { nzDuration: 5000 });
        console.error("GET TABLE: ", error);
      }
    );
  }

  // ****************** 2023/07/10 Alberto Baggio *****************************************************************************************
  standardCover(cover) {
    if (!this.standardCoverDisabled) {
      const formData = new FormData();
      formData.append('file', cover.file + cover.extension);
      formData.append('folder', cover.folder);
      formData.append('cd_preventivo', this.quoteId);
      formData.append('officialName', cover.officialName);
      this.data.setStandardCover(formData)
        .subscribe((result) => {
          if (result.success) {
            this.allegato = result.file;
          }
        });

      cover = this.standardCoverList[0];
    }
  }
  // *****************************************************************************************************************************************

  beforeUpload = (file: NzUploadFile): boolean => {
    this.uploadFile = file;
    return true;
  };
  // *************************** 2023/09/10 Alberto Baggio *********************************************************************************

  beforeUploadAnagrafica = (file: NzUploadFile): boolean => {
    this.uploadFile = file;
    return true;
  };

  beforeUploadPrivacy = (file: NzUploadFile): boolean => {
    this.uploadFile = file;
    return true;
  };

  handlePreview(event) {
    if (this.allegato) {
      event.stopPropagation();
      this.modal.create({
        nzWidth: "80%",
        nzContent: this.tplPreview,
        nzCentered: true,
        nzCancelText: null,
        nzComponentParams: {
          file: this.g.covPath + this.allegato,
        },
      });
    }
  }

  handlePreviewAnagrafica(event) {
    if (this.anagrafica) {
      event.stopPropagation();
      this.modal.create({
        nzWidth: "80%",
        nzContent: this.tplPreview,
        nzCentered: true,
        nzCancelText: null,
        nzComponentParams: {
          file: this.g.anagraficaPath + this.anagrafica,
        },
      });
    }
  }

  handlePreviewPrivacy(event) {
    if (this.privacy) {
      event.stopPropagation();
      this.modal.create({
        nzWidth: "80%",
        nzContent: this.tplPreview,
        nzCentered: true,
        nzCancelText: null,
        nzComponentParams: {
          file: this.g.privacyPath + this.privacy,
        },
      });
    }
  }

  handleUpload = (item: any) => {
    this.isUploading = true;
    // ****************** 2023/07/10 Alberto Baggio *****************************************************************************************
    this.standardCoverDisabled = true;
    // *****************************************************************************************************************************************
    const formData = new FormData();
    const ext = item.file.name.substr(item.file.name.lastIndexOf("."));
    const filename = this.quoteId + "-allegato" + ext;
    formData.append("file", item.file as any);
    formData.append("filename", filename);
    formData.append("cd_preventivo", this.quoteId);

    const req = new HttpRequest("POST", item.action!, formData, {
      reportProgress: true,
    });
    return this.http.request(req).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file!, event);
          this.allegato = filename;
          this.isUploading = false;
        }
      },
      (err) => {
        console.log("Upload failed", err);
        this.isUploading = false;
        this.uploadFile = null;
        this.allegato = "";
        this.message.error(`Upload failed.`);
      }
    );
  };

  handleUploadAnagrafica = (item: any) => {
    this.isUploadingAnagrafica = true;
    const formData = new FormData();
    const ext = item.file.name.substr(item.file.name.lastIndexOf("."));
    const filename = this.quoteId + '-anagrafica' + ext;
    formData.append("file", item.file as any);
    formData.append("filename", filename);
    formData.append("cd_preventivo", this.quoteId);

    const req = new HttpRequest("POST", item.action!, formData, {
      reportProgress: true,
    });
    return this.http.request(req).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file!, event);
          this.anagrafica = filename;
          this.isUploadingAnagrafica = false;
        }
      },
      (err) => {
        console.log("Upload failed", err);
        this.isUploadingAnagrafica = false;
        this.uploadFile = null;
        this.anagrafica = "";
        this.message.error(`Upload failed.`);
      }
    );
  };

  handleUploadPrivacy = (item: any) => {
    this.isUploadingPrivacy = true;
    const formData = new FormData();
    const ext = item.file.name.substr(item.file.name.lastIndexOf("."));
    const filename = this.quoteId + '-pivacy' + ext;
    formData.append("file", item.file as any);
    formData.append("filename", filename);
    formData.append("cd_preventivo", this.quoteId);

    const req = new HttpRequest("POST", item.action!, formData, {
      reportProgress: true,
    });
    return this.http.request(req).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total! > 0) {
            (event as any).percent = (event.loaded / event.total!) * 100;
          }
          item.onProgress!(event, item.file!);
        } else if (event instanceof HttpResponse) {
          item.onSuccess!(event.body, item.file!, event);
          this.privacy = filename;
          this.isUploadingPrivacy = false;
        }
      },
      (err) => {
        console.log("Upload failed", err);
        this.isUploadingPrivacy = false;
        this.uploadFile = null;
        this.privacy = "";
        this.message.error(`Upload failed.`);
      }
    );
  };

  removeFile(event) {
    event.stopPropagation();
    this.isUploading = true;
    // ****************** 2023/07/10 Alberto Baggio *****************************************************************************************
    this.standardCoverDisabled = false;
    // *****************************************************************************************************************************************
    const formData = new FormData();
    formData.append("filename", this.allegato);
    formData.append("cd_preventivo", this.quoteId);
    console.log(this.removeUrl);
    this.http.post(this.removeUrl, formData).subscribe(
      (res) => {
        this.isUploading = false;
        if (res) {
          this.allegato = "";
        } else {
          this.message.error(`Remove failed.`);
        }
      },
      (err) => {
        this.isUploading = false;
        this.message.error(`Remove failed.`);
      }
    );
    // ********************* 2023/07/11 Alberto Baggio ************************************************************************
    this.selectedCover = this.standardCoverList[0];
    // *************************************************************************************************************************

  }

  removeFileAnagrafica(event) {
    event.stopPropagation();
    this.isUploadingAnagrafica = true;
    const formData = new FormData();
    formData.append("filename", this.anagrafica);
    formData.append("cd_preventivo", this.quoteId);
    this.http.post(this.removeUrlAnagrafica, formData).subscribe(
      (res) => {
        this.isUploadingAnagrafica = false;
        if (res) {
          this.anagrafica = "";
        } else {
          this.message.error(`Remove failed.`);
        }
      },
      (err) => {
        this.isUploadingAnagrafica = false;
        this.message.error(`Remove failed.`);
      }
    );
  }

  removeFilePrivacy(event) {
    event.stopPropagation();
    this.isUploadingPrivacy = true;
    const formData = new FormData();
    formData.append("filename", this.privacy);
    formData.append("cd_preventivo", this.quoteId);
    this.http.post(this.removeUrlPrivacy, formData).subscribe(
      (res) => {
        this.isUploadingPrivacy = false;
        if (res) {
          this.privacy = "";
        } else {
          this.message.error(`Remove failed.`);
        }
      },
      (err) => {
        this.isUploadingPrivacy = false;
        this.message.error(`Remove failed.`);
      }
    );
  }

  uploadFilters: UploadFilter[] = [
    {
      name: "get-type",
      fn: (filelist: NzUploadFile[]) => {
        filelist.forEach((f: NzUploadFile) => {
          if (f.type != "image/png" && f.type != "image/jpeg") {
            this.message.create("error", "Sono ammessi solo file .PNG o .JPEG");
            filelist = [];
            return;
          }
        });
        return filelist;
      },
    },
  ];

  uploadFiltersPdf: UploadFilter[] = [
    {
      name: "get-type",
      fn: (filelist: NzUploadFile[]) => {
        filelist.forEach((f: NzUploadFile) => {
          if (f.type != "application/pdf" && f.type != "application/pdf") {
            this.message.create("error", "Sono ammessi solo file .pdf");
            filelist = [];
            return;
          }
        });
        return filelist;
      },
    },
  ];
  // *****************************************************************************************************************************************



  updateRicarico($event, data) {
    if (data.bakRicarico == null) data.bakRicarico = data.ricarico;
    data.ricarico = $event;
    this.updateTotali();
  }

  cancelRicarico(data) {
    data.ricarico = data.bakRicarico;
    data.bakRicarico = null;
    this.updateTotali();
  }

  saveRicarico(data) {
    let i = this.quoteProds.findIndex((prod) => prod === data);
    data.bakRicarico = null;
    this.updateQuoteRow(i);
  }

  // ******************** 2023/07/05 Alberto Baggio *************************************************************************

  sumGroupTotal(quoteProd) {

    let color = quoteProd.color;

    this.groupTotal[color.id].cost = this.groupTotal[color.id].cost + (quoteProd.unit_manodopera + quoteProd.unit_materiale) * quoteProd.quantita;
    if (quoteProd.ricarico > 0) {
      this.groupTotal[color.id].total = this.groupTotal[color.id].total + ((quoteProd.unit_manodopera + quoteProd.unit_materiale) * quoteProd.quantita) / quoteProd.ricarico;
    } else {
      this.groupTotal[color.id].total = this.groupTotal[color.id].total + (quoteProd.unit_manodopera + quoteProd.unit_materiale) * quoteProd.quantita;
    }

  }

  substractGroupTotal(quoteProd, prevColor) {

    if (this.groupTotal[prevColor.id].cost > 0) {
      this.groupTotal[prevColor.id].cost = this.groupTotal[prevColor.id].cost - (quoteProd.unit_manodopera + quoteProd.unit_materiale) * quoteProd.quantita;
      if (quoteProd.ricarico > 0) {
        this.groupTotal[prevColor.id].total = this.groupTotal[prevColor.id].total - ((quoteProd.unit_manodopera + quoteProd.unit_materiale) * quoteProd.quantita) / quoteProd.ricarico;
      } else {
        this.groupTotal[prevColor.id].total = this.groupTotal[prevColor.id].total - (quoteProd.unit_manodopera + quoteProd.unit_materiale) * quoteProd.quantita;
      }
    }
  }

  calculateConstAndPrev() {
    let prevCost = 0;
    let prevTot = 0;

    this.groupTotal.forEach(groupElement => {
      if ((groupElement.option !== 'H' && groupElement.option !== 'I')) {
        prevCost += groupElement.cost;
        prevTot += groupElement.total;
      }
    });

    return { prevCost, prevTot };
  }

  saveButtonDisabled() {
    if (
      this.edit ||
      this.editGroups ||
      this.editCondition ||
      this.editClauses ||
      this.editHead
    ) {
      return true;
    }
    return false;
  }
  // *******************************************************************************************************************************************************
  resetData() {
    // reset data
    this.prevQta = 0;
    this.prevTot = 0;
    this.prevRic = 0;
    this.prevCost = 0;
    this.groupTotal = [{ cost: 0, total: 0, option: '' }];

    this.standardCoverList = [{ folder: '', file: 'Seleziona' }];
    this.standardCoverDisabled = false;
    this.selectedCover = this.standardCoverList[0];
  }

  rebuild(result) {
    this.selectQuote = result[0];
    this.quoteVersion = result[0].versione;
    this.lastVersion = result[0].versione;
    this.allegato = result[0].allegato;
    this.anagrafica = result[0].anagrafica;
    this.privacy = result[0].privacy;
    const storedQuote = this.storage.getSelectedQuote();
    if (storedQuote && storedQuote.contact) {
      this.quoteContact = storedQuote.contact;
    } else {
      this.quoteContact = result[0].contact as any;
    }
    if (storedQuote && storedQuote.contact) {
      this.groups = storedQuote.groups;
      this.conditions = storedQuote.conditions;
      this.clauses = storedQuote.clauses;
      this.selectQuote.preventivista = storedQuote.preventivista;
    } else {
      this.groups = JSON.parse(result[0].groups as any);
      this.conditions = JSON.parse(result[0].conditions);
      this.clauses = result[0].clauses;
    }

    this.quoteProds = result[0].rows as any[];
    this.g.actualPage = this.translate.instant("newQuote");
    this.updateEditCache();
    this.updateEditGroups();
    this.updateEditConditions();
    this.updateEditClauses();
    this.updateTotali(true);
    // *********************** 2023/07/04 Alberto Basggio ****************************************************************************************
    this.groups.forEach(group => {
      this.groupTotal.push({ cost: 0, total: 0, option: group.option });
    });

    this.quoteProds.forEach(quoteProd => {
      this.sumGroupTotal(quoteProd);
    });

    let tot = this.calculateConstAndPrev();
    this.prevCost = tot.prevCost;
    this.prevTot = tot.prevTot;
    // **********************************************************************************************************

    this.loading = false;
    let selectedQuote = {
      id: this.quoteId,
      version: this.quoteVersion,
      contact: this.quoteContact,
      total: this.prevTot,
      qta: this.prevQta,
      groups: this.groups,
      conditions: this.conditions,
      clauses: this.clauses,
      preventivista: this.selectQuote.preventivista,
    };
    this.storage.setSelectedQuote(selectedQuote);
    this.reorderRows();
    this.quoteUnfilteredProds = [...this.quoteProds];

    if (this.selectQuote.stato === "Completato") {
      this.enableEdit = false;
    }

    this.data.getTable("settings").subscribe(
      (result) => {
        let settings = result;
        settings.forEach((set) => {
          if (set.nome === "tipo_fatturazione") {
            this.billingSettings = set.valore.split('|');
          } else if (set.nome === "tipo_pagamento") {
            this.paymentSettings = set.valore.split('|');
          }
        });
      },
      (error) => {
        this.loading = false;
        this.message.create("error", "Errore", { nzDuration: 5000 });
        console.error("GET TABLE: ", error);
      }
    );

    this.data.getStandardCover().subscribe((data) => {
      data.map(cover => {
        cover.extension = cover.file.substr(cover.file.lastIndexOf("."));
        cover.officialName = this.quoteId + "-allegato" + cover.extension;
        cover.file = cover.file.replace(cover.extension, '');
      });

      this.standardCoverList = [...this.standardCoverList, ...data];
    });
  }


  cleanOldGroupError(oldResult) {
    let groupsList = JSON.parse(oldResult.groups);

    groupsList = groupsList.filter((group: any) => {
      if (group.id !== 0) {
        return group;
      }
    });

    return JSON.stringify(groupsList);
  }
}


