import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { ProductAddComponent } from './pages/product-add/product-add.component';
import { AddComponent } from './pages/add/add.component';
import { AdminRoutingModule } from './pages/admin/admin-routing.module';
import { ProductEditComponent } from './pages/product-edit/product-edit.component';
import { EditComponent } from './pages/edit/edit.component';
import { HistoryComponent } from './pages/history/history.component';
import { LoginComponent } from './pages/login/login.component';
import { QuoteComponent } from './pages/quote/quote.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { CategoriesComponent } from './pages/admin/categories/categories.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
    canActivateChild: [LoginGuard],
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'history',
    component: HistoryComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'quote/:id',
    component: QuoteComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'quote/:id/add',
    component: AddComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'quote/:id/edit/:row',
    component: EditComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'product-add',
    component: ProductAddComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'product-edit/:id',
    component: ProductEditComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'categorie',
    component: CategoriesComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  { path: '**', pathMatch: 'full', redirectTo: '/history' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), AdminRoutingModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
