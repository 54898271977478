<nz-layout class="uiUserAdminInnerLayout">
    <nz-content>
        <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input placeholder="Ricerca" (input)="filter($event.target.value)"  #searchInput/>
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <i class="fad fa-search"></i>
        </ng-template>
        <nz-table #dataTable nzBordered nzSize="middle" [nzData]="displayedData" [nzLoading]="loading"
            nzPaginationPosition="top" [nzSimple]="true" class="uiAdminTable">
            <thead>
                <tr>
                    <!-- <th nzWidth="60px"></th> -->
                    <th nzAlign="center">Id</th>
                    <th nzAlign="center">{{'custSoc' | translate}}</th>
                    <th nzAlign="center">{{'custAdress' | translate}}</th>
                    <th nzAlign="center">{{'custIva' | translate}}</th>
                    <th nzAlign="center">{{'custCF' | translate}}</th>
                    <th nzAlign="center">{{'custGroup' | translate}}</th>
                    <th nzAlign="center">{{'custNote' | translate}}</th>
                    <th nzAlign="center">{{'custSeller' | translate}}</th>
                </tr>
            </thead>
            <tbody class="uiAdminTableBody">
                <ng-container *ngFor="let data of dataTable.data; let i = index">
                    <tr (click)="onExpandChange(data.cd_cf, $event)" [ngClass]="{'selected uiSelectableRow': expandSet === data.cd_cf, 'uiSelectableRow': expandSet !== data.cd_cf, 'uiColoredRow': i%2 !== 0}">
                        <td nzLeft="0px" nzAlign="center">
                            {{data.cd_cf}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.descrizione}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.indirizzo}} - {{data.localita}} {{data.cap}} ({{data.cd_nazione}}, {{data.cd_provincia}})
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.partitaiva}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.codicefiscale}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.gruppo}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.note_cf}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <span *ngIf="data.codiceV">{{data.codiceV}}: {{data.nomeV}} {{data.cognomeV}}</span>
                        </td>
                    </tr>
                    <tr [nzExpand]="expandSet === data.cd_cf" class="uiCustContactTable">
                        <nz-table #dataCont nzBordered nzSize="middle" [nzData]="contResult" [nzLoading]="loading"
                            nzShowPagination="false" [nzSimple]="true" class="uiAdminTable">
                            <thead>
                                <tr class="uiCustContactTableHead">
                                    <th nzAlign="center">{{'custName' | translate}}</th>
                                    <th nzAlign="center">{{'custTitle' | translate}}</th>
                                    <th nzAlign="center">{{'custTel' | translate}}</th>
                                    <th nzAlign="center">{{'custTel2' | translate}}</th>
                                    <th nzAlign="center">{{'custCell' | translate}}</th>
                                    <th nzAlign="center">{{'custCell2' | translate}}</th>
                                    <th nzAlign="center">{{'custEmail' | translate}}</th>
                                    <th nzAlign="center">{{'custPec' | translate}}</th>
                                    <th nzAlign="center">{{'custUrl' | translate}}</th>
                                    <th nzAlign="center">{{'custNote' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody class="uiAdminTableBody">
                                <tr *ngFor="let cont of dataCont.data; let j = index" (click)="selectContact(data.cd_cf, j)" class="uiSelectableRow uiContactRow">
                                    <td nzLeft="0px" nzAlign="center">
                                        {{cont.nome}}
                                    </td>
                                    <td nzLeft="0px" nzAlign="center">
                                        {{cont.titolo}}
                                    </td>
                                    <td nzLeft="0px" nzAlign="center">
                                        {{cont.telefono}}
                                    </td>
                                    <td nzLeft="0px" nzAlign="center">
                                        {{cont.telefono2}}
                                    </td>
                                    <td nzLeft="0px" nzAlign="center">
                                        {{cont.cellulare}}
                                    </td>
                                    <td nzLeft="0px" nzAlign="center">
                                        {{cont.cellulare2}}
                                    </td>
                                    <td nzLeft="0px" nzAlign="center">
                                        {{cont.email}}
                                    </td>
                                    <td nzLeft="0px" nzAlign="center">
                                        {{cont.emailpec}}
                                    </td>
                                    <td nzLeft="0px" nzAlign="center">
                                        <a href="{{cont.url}}" target="_blank">{{cont.url}}</a>
                                    </td>
                                    <td nzLeft="0px" nzAlign="center">
                                        {{cont.note}}
                                    </td>
                                    <!-- <td nzLeft="0px" nzAlign="center">
                                        <i class="fad fa-check-circle" (click)="selectContact(i, j)"></i>
                                    </td> -->
                                </tr>
                            </tbody>
                        </nz-table>
                    </tr>
                </ng-container>
            </tbody>
        </nz-table>
    </nz-content>
</nz-layout>