import { Injectable } from "@angular/core";
import crc32 from "crc/crc32";
import { SodiumPlus, CryptographyKey } from "sodium-plus";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  // yy.mm.dd
  appVersion = "v24.09.12";
  actualPage = "";
  selectedLanguage = "it";
  selectedQuote = "";
  selectedVersion = "";

  loggedUser: any = { nome: "", cognome: "" };

  keyBuffer: Buffer;
  phpPath: string;
  imgPath: string;
  pdfPath: string;
  covPath: string;
  anagraficaPath: string;
  privacyPath: string;
  sodium: SodiumPlus;

  constructor() {
    this.phpPath = "https://preventivi.tecnostile.com/php";
    this.imgPath = "https://preventivi.tecnostile.com/php/assets/";
    this.pdfPath = "https://preventivi.tecnostile.com/php/assets/preventivi/";
    this.covPath = "https://preventivi.tecnostile.com/php/assets/copertine/";
    this.anagraficaPath = "https://preventivi.tecnostile.com/php/assets/anagrafica/";
    this.privacyPath = "https://preventivi.tecnostile.com/php/assets/privacy/";

    if (document.location.hostname == "localhost") {
      // ################################### DEVELOPMENT
      this.phpPath = "http://localhost:3000/php";
      this.imgPath = "http://localhost:3000/php/assets/";
      this.pdfPath = "http://localhost:3000/php/assets/preventivi/";
      this.covPath = "http://localhost:3000/php/assets/copertine/";
      this.anagraficaPath = "http://localhost:3000/php/assets/anagrafica/";
      this.privacyPath = "http://localhost:3000/php/assets/privacy/";
    } else {
      // ################################### PRODUCTION
      this.phpPath = "/php";
      this.imgPath = "/php/assets/";
      this.pdfPath = "/php/assets/preventivi/";
      this.covPath = "/php/assets/copertine/";
      this.anagraficaPath = "/php/assets/anagrafica/";
      this.privacyPath = "/php/assets/privacy/";
    }
  }

  /**
   * @desc save Quote in session storage
   * @param id - Quote's id
   * @return void
   */
  saveQuote(id: string) {
    sessionStorage.setItem("SelectedQuote", id);
  }

  /**
   * @desc return session storage saved Quote id
   * @param -
   * @return saved Quote
   */
  getQuote() {
    return sessionStorage.getItem("SelectedQuote");
  }

  /**
   * @desc remove session storage saved Quote
   * @param -
   * @return void
   */
  resetQuote() {
    sessionStorage.removeItem("SelectedQuote");
  }

  saveKey(data: string) {
    this.keyBuffer = Buffer.from(data, "utf8");
  }

  /**
   * @desc encrypt data
   * @param data - decrypted data
   * @return encrypted data
   */
  async encryptDataGlobal(data: string) {
    const crc32Key = crc32(data);
    const base64Key = btoa(crc32Key);

    if (!this.sodium) {
      this.sodium = await SodiumPlus.auto();
    }
    const nonce = await this.sodium.randombytes_buf(24);
    const keyPair = new CryptographyKey(this.keyBuffer);
    const sKey = await this.sodium.crypto_box_secretkey(keyPair);
    const pKey = await this.sodium.crypto_box_publickey(keyPair);
    const encrypted = await this.sodium.crypto_box(base64Key, nonce, sKey, pKey);

    return nonce.toString("hex") + encrypted.toString("hex");
  }

  randomMath() {
    return Math.random();
  }

  // ########################################## LOG
  VerboseLog: boolean = true;
  public log(msg: any, ...optionalParams) {
    if (environment.production) return;
    if (this.VerboseLog) {
      if (optionalParams) {
        console.log(msg, optionalParams);
      } else {
        console.log(msg);
      }
    }
  }
}
