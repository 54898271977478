import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { TblVenditori } from "src/app/entities/TblVenditori";
import { DataService } from "src/app/services/data.service";
import { GlobalService } from "src/app/shared/global.service";

@Component({
	selector: "app-sellers",
	templateUrl: "./sellers.component.html",
	styleUrls: ["./sellers.component.scss"],
})
export class SellersComponent implements OnInit {
	showForm = false;

	dataResult: TblVenditori[];
	displayedData: any[];
	loading = true;
	editCache: { [key: string]: { edit: boolean; data: TblVenditori } } = {};
	addForm: FormGroup;

	constructor(
		private fb: FormBuilder,
		private data: DataService,
		private message: NzMessageService,
		private g: GlobalService,
		private translate: TranslateService
	) {}

	ngOnInit() {
		// get all users
		this.data.getTable("sellers").subscribe(
			(result) => {
				this.dataResult = result as TblVenditori[];
				this.displayedData = [...this.dataResult];
				this.loading = false;
				this.displayedData.forEach((data) => {
					data.visible = false;
				});

				this.updateEditCache();
			},
			(error) => {
				this.message.create("error", "Errore", { nzDuration: 5000 });
				this.loading = false;
				console.error("GET TABLE: ", error);
			}
		);
	}

	updateEditCache(): void {
		this.dataResult.forEach((item) => {
			this.editCache[item.cd_venditore] = {
				edit: false,
				data: { ...item },
			};
		});
	}

	filter(searchValue: string) {
		this.displayedData = this.dataResult.filter(
			(item: TblVenditori) =>
				item.cd_venditore.toString().indexOf(searchValue.toLowerCase()) !== -1 ||
				item.email.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
				item.nome.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
				item.cognome.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
		);
	}

	startEdit(id: string): void {
		this.editCache[id].edit = true;
	}

	cancelEdit(id: string): void {
		const index = this.dataResult.findIndex((item) => item.cd_venditore.toString() === id);
		this.editCache[id] = {
			data: { ...this.dataResult[index] },
			edit: false,
		};
	}

	async saveEdit(id: string) {
		const checkSumKey = await this.g.encryptDataGlobal(
			"TblVenditori" +
				id +
				this.editCache[id].data.nome +
				this.editCache[id].data.cognome +
				this.editCache[id].data.email
		);
		const formData = new FormData();
		formData.append("id", id);
		formData.append("nome", this.editCache[id].data.nome);
		formData.append("cognome", this.editCache[id].data.cognome);
		formData.append("email", this.editCache[id].data.email);
		formData.append("telefono", this.editCache[id].data.telefono);
		formData.append("checkKey", checkSumKey);

		this.data.editSeller(formData).subscribe(
			(result) => {
				this.editCache[id].edit = false;
				const index = this.dataResult.findIndex((item) => item.cd_venditore.toString() == id);
				Object.assign(this.dataResult[index], this.editCache[id].data);
				Object.assign(this.displayedData[index], this.editCache[id].data);
				this.message.create("success", "User edited successfully", { nzDuration: 5000 });
			},
			(error) => {
				this.message.create("error", "Errore", { nzDuration: 5000 });
				console.error("CHANGE SWITCH: ", error);
			}
		);
	}

	showModal(): void {
		this.showForm = true;
		this.addForm = this.fb.group({
			nome: new FormControl("", [Validators.required]),
			cognome: new FormControl("", [Validators.required]),
			email: new FormControl("", [Validators.required]),
			telefono: new FormControl("", [Validators.required]),
		});
	}

	handleCancel() {
		this.showForm = false;
	}

	async addSeller() {
		const checkSumKey = await this.g.encryptDataGlobal(
			"TblVenditori" + this.addForm.value.nome + this.addForm.value.cognome + this.addForm.value.email
		);
		const formData = new FormData();
		formData.append("nome", this.addForm.value.nome);
		formData.append("cognome", this.addForm.value.cognome);
		formData.append("email", this.addForm.value.email);
		formData.append("telefono", this.addForm.value.telefono);
		formData.append("checkKey", checkSumKey);

		this.data.addSeller(formData).subscribe(
			(result) => {
				this.showForm = false;
				this.ngOnInit();
				this.message.create("success", "User added succesfully", { nzDuration: 5000 });
			},
			(error) => {
				this.message.create("error", "Errore", { nzDuration: 5000 });
				console.error("ADD SELLER ", error);
			}
		);
	}

	async deleteSeller(id: string) {
		const index = this.dataResult.findIndex((item) => item.cd_venditore.toString() === id.toString());
		const checkSumKey = await this.g.encryptDataGlobal("TblVenditori" + id);
		const formData = new FormData();
		formData.append("id", id);
		formData.append("checkKey", checkSumKey);
		this.data.deleteSeller(formData).subscribe(
			(result) => {
				this.dataResult = this.dataResult.filter((d) => d.cd_venditore.toString() !== id.toString());
				this.displayedData = [...this.dataResult];
				this.message.create("success", "Seller deleted successfully", { nzDuration: 5000 });
			},
			(error) => {
				this.message.create("error", "Errore", { nzDuration: 5000 });
				console.error("DELETE SELLER: ", error);
			}
		);
	}
}
