<nz-layout>
    <nz-header class="uiAdminHeader" *ngIf="g.actualPage">
        <div nz-row class="uiHeaderAdminRow">
            <div class="uiHeaderAdminLeftCol" nz-col nzSpan="16">
                <a href="/"><img class="uiUncollapsedLogo" src="../../../assets/logo.png" alt="logo"></a>
                <span *ngIf="g.actualPage === 'history'" class="uiWelcome">{{'welcome' | translate}}, {{g.loggedUser["nome"]}} {{g.loggedUser["cognome"]}}</span>
            </div>
            <div nz-col nzSpan="8" class="uiHeaderAdminRightCol">
                <button nz-button nzSize="large" nzType="primary" class="uiHomeBtn" type="button" (click)="goTo('admin')">
                    <i class="fad fad-swap-color fa-cog uiAdminIcon" *ngIf="auth.isLoggedIn() && auth.getUser().ruolo !== 'utente'"></i>
                </button>
                <i class="fad fa-user uiHeaderHomeBtn" *ngIf="auth.isLoggedIn()"></i>
                <span class="uiLogoutSpan" *ngIf="auth.isLoggedIn()">{{g.loggedUser["cognome"]}}</span><i *ngIf="auth.isLoggedIn()" class="fad fa-sign-out uiLogoutBtn" (click)="auth.logout()"></i>
            </div>
        </div>
    </nz-header>
    <nz-content>
        <router-outlet></router-outlet>
    </nz-content>
    <nz-footer class="uiFooter">
        <div style="float: left">{{g.appVersion}} Copyright ©2023 Tecnostile S.r.l.</div>
        <div style="float: right">made by <a class="ocalab" href="https://www.ocalab.it"><b>OCALAB</b></a></div>
    </nz-footer>
</nz-layout>