<nz-layout class="uiUserAdminInnerLayout">
    <nz-content>
        <div class="uiAdminAddContainer">
            <button nz-button nzType="primary" class="uiAdminAddBtn" (click)="showModal()">
                <i class="fad fa-plus-square"></i>
                {{'sellerAdd' | translate}}
            </button>
            <nz-modal [(nzVisible)]="showForm" nzTitle="{{'sellerAdd' | translate}}" nzFooter="null" (nzOnCancel)="handleCancel()">
                <ng-container *nzModalContent>
                    <form [formGroup]="addForm" class="uiAddForm">
                        <input nz-input formControlName="nome" placeholder="{{'userName' | translate}}" />
                        <input nz-input formControlName="cognome" placeholder="{{'userSurname' | translate}}" />
                        <input nz-input formControlName="email" placeholder="{{'userEmail' | translate}}" />
                        <input type="tel" nz-input formControlName="telefono" placeholder="{{'userTel' | translate}}" />
                    </form>
                </ng-container>
                <div *nzModalFooter>
                    <button nz-button nzType="primary" class="uiModalBackBtn" (click)="handleCancel()">
                        {{'cancel' | translate}}
                    </button>
                    <button nz-button nzType="primary" [disabled]="addForm.invalid" class="uiModalAddBtn" (click)="addSeller()">{{'btn.add' | translate}}</button>
                </div>
            </nz-modal>
        </div>
        <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input placeholder="Ricerca" (input)="filter($event.target.value)" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <i class="fad fa-search"></i>
        </ng-template>
        <nz-table #dataTable nzBordered nzSize="middle" [nzData]="displayedData" [nzPageSize]="30" [nzLoading]="loading" nzPaginationPosition="both" [nzSimple]="true" class="uiAdminTable">
            <thead>
                <tr>
                    <th nzAlign="center">Id</th>
                    <th nzAlign="center">{{'userName' | translate}}</th>
                    <th nzAlign="center">{{'userEmail' | translate}}</th>
                    <th nzAlign="center">{{'userTel' | translate}}</th>
                    <th nzAlign="center"></th>
                </tr>
            </thead>
            <tbody class="uiAdminTableBody">
                <tr *ngFor="let data of dataTable.data; let i = index" [ngClass]="i%2 !== 0 ? 'uiColoredRow' : ''">
                    <ng-container *ngIf="!editCache[data.cd_venditore].edit; else editTemplate">
                        <td nzLeft="0px" nzAlign="center">
                            {{data.cd_venditore}}
                        </td>
                        <td nzLeft="0px" nzAlign="left">
                            {{data.nome}} {{data.cognome}}
                        </td>
                        <td nzLeft="0px" nzAlign="left">
                            {{data.email}}
                        </td>
                        <td nzLeft="0px" nzAlign="left">
                            {{data.telefono}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <i class="fad fa-edit uiAdminUserAction" (click)="startEdit(data.cd_venditore)" nzTooltipTitle="{{'editSeller' | translate}}" nzTooltipPlacement="top" nz-tooltip></i>
                            <br />
                            <br />
                            <i class="fad fa-trash uiAdminUserAction" nz-popconfirm nzPopconfirmTitle="{{'deleteSellerAlert' | translate}}" nzPopconfirmPlacement="top" (nzOnConfirm)="deleteSeller(data.cd_venditore, i)" nzTooltipTitle="{{'deleteSeller' | translate}}" nzTooltipPlacement="top" nz-tooltip></i>
                        </td>
                    </ng-container>
                    <ng-template #editTemplate>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.cd_venditore}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <input type="text" nz-input [(ngModel)]="editCache[data.cd_venditore].data.nome" />
                            <input type="text" nz-input [(ngModel)]="editCache[data.cd_venditore].data.cognome" />
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <input type="text" nz-input [(ngModel)]="editCache[data.cd_venditore].data.email" />
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <input type="tel" nz-input [(ngModel)]="editCache[data.cd_venditore].data.telefono" />
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <a (click)="saveEdit(data.cd_venditore)" class="save">{{'save' | translate}}</a>
                            <br />
                            <a (click)="cancelEdit(data.cd_venditore)">{{'cancel' | translate}}</a>
                        </td>
                    </ng-template>
                </tr>
            </tbody>
        </nz-table>
    </nz-content>
</nz-layout>