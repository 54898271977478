import { Column, Entity } from "typeorm";

@Entity("TblContattiClienti", { schema: "app_tecnostile" })
export class TblContattiClienti {
  @Column("varchar", { primary: true, name: "id", length: 15 })
  id: string;

  @Column("varchar", { name: "cd_cf", length: 15 })
  cd_cf: string;

  @Column("varchar", { name: "nome", length: 100 })
  nome: string;

  @Column("varchar", { name: "cognome", length: 100 })
  cognome: string;

  @Column("varchar", { name: "titolo", length: 100 })
  titolo: string;

  @Column("varchar", { name: "telefono", length: 50 })
  telefono: string;

  @Column("varchar", { name: "telefono2", length: 50 })
  telefono2: string;

  @Column("varchar", { name: "cellulare", length: 50 })
  cellulare: string;

  @Column("varchar", { name: "cellulare2", length: 50 })
  cellulare2: string;

  @Column("varchar", { name: "email", length: 100 })
  email: string;

  @Column("varchar", { name: "emailpec", length: 100 })
  emailpec: string;

  @Column("varchar", { name: "url", length: 100 })
  url: string;

  @Column("varchar", { name: "note", length: 200 })
  note: string;

  @Column("int", { name: "sequenza" })
  sequenza: number;
}
