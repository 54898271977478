<nz-header class="uiLoginHeader">
    <div class="uiHeaderLoginRow">
        <img class="uiUncollapsedLogo" src="../../../assets/logo.png" alt="logo">
    </div>
</nz-header>

<div class="uiLoginContainer">
    <div class="uiFormContainer">
        <h1 class="uiLoginTitle">Preventivatore</h1>
        <p class="uiDescriptionText" [innerHTML]="'descriptionText' | translate"></p>
        <input type="text" nz-input [(ngModel)]="username" placeholder="Email" (keydown.enter)="onSubmit()" />
        <br />
        <input type="password" nz-input [(ngModel)]="password" placeholder="Password" (keydown.enter)="onSubmit()" />
        <br />
        <button nz-button nzSize="large" (click)="onSubmit()" nzType="primary" class="loginBtn">{{'login' |
            translate}}</button>
        <p *ngIf="showError" class="errorMessage">{{'wrongLogin' | translate}}</p>
        <br />
    </div>
</div>