<nz-layout class="uiUserAdminInnerLayout">
    <nz-content>
        <div class="uiAdminAddContainer">
            <button nz-button nzType="primary" class="uiAdminAddBtn" (click)="showModal()">
                <i class="fad fa-plus-square"></i>
                {{'userAdd' | translate}}
            </button>
            <nz-modal [(nzVisible)]="showForm" nzTitle="{{'userAdd' | translate}}" nzFooter="null" (nzOnCancel)="handleCancel()">
                <ng-container *nzModalContent>
                    <form [formGroup]="userForm" class="uiAddForm">
                        <input nz-input formControlName="nome" placeholder="{{'userName' | translate}}" />
                        <input nz-input formControlName="cognome" placeholder="{{'userSurname' | translate}}" />
                        <nz-select nzShowSearch nzAllowClear formControlName="venditore" nzPlaceHolder="{{'userSeller' | translate}}">
                            <nz-option nzValue="--" nzLabel="--"></nz-option>
                            <nz-option *ngFor="let seller of sellers" [nzValue]="seller.label" [nzLabel]="seller.label">
                            </nz-option>
                        </nz-select>
                        <nz-select formControlName="ruolo" nzPlaceHolder="{{'userRole' | translate}}">
                            <nz-option nzValue="superadmin" nzLabel="superadmin"></nz-option>
                            <nz-option nzValue="admin" nzLabel="admin"></nz-option>
                            <nz-option nzValue="normale" nzLabel="normale"></nz-option>
                        </nz-select>
                        <input nz-input formControlName="email" placeholder="{{'userEmail' | translate}}" />
                        <input type="password" nz-input formControlName="password" placeholder="{{'userPwd' | translate}}" />
                    </form>
                </ng-container>
                <div *nzModalFooter>
                    <button nz-button nzType="primary" class="uiModalBackBtn" (click)="handleCancel()">
                        {{'cancel' | translate}}
                    </button>
                    <button nz-button nzType="primary" [disabled]="userForm.invalid" class="uiModalAddBtn" (click)="addUser()" nzTooltipTitle="{{'tootltip.add' | translate}}" nzTooltipPlacement="top" nz-tooltip>{{'add' | translate}}</button>
                </div>
            </nz-modal>
        </div>
        <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input placeholder="Ricerca" (input)="filter($event.target.value)" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <i class="fad fa-search"></i>
        </ng-template>

        <nz-table #dataTable nzBordered nzSize="middle" [nzData]="displayedData" [nzPageSize]="30" [nzLoading]="loading" nzPaginationPosition="both" [nzSimple]="true" class="uiAdminTable">
            <thead>
                <tr>
                    <th nzAlign="center">Id</th>
                    <th nzAlign="center">{{'userName' | translate}}</th>
                    <th nzAlign="center">{{'userSeller' | translate}}</th>
                    <th nzAlign="center">{{'userEmail' | translate}}</th>
                    <th nzAlign="center">{{'userRole' | translate}}</th>
                    <th nzAlign="center">{{'userWrite' | translate}}</th>
                    <th nzAlign="center">{{'userActive' | translate}}</th>
                    <th nzAlign="center">{{'userPwd' | translate}}</th>
                    <th nzAlign="center">{{'userLast' | translate}}</th>
                    <th nzAlign="center"></th>
                </tr>
            </thead>
            <tbody class="uiAdminTableBody">
                <tr *ngFor="let data of dataTable.data; let i = index" [ngClass]="i%2 !== 0 ? 'uiColoredRow' : ''">
                    <ng-container *ngIf="!editCache[data.cd_utente].edit; else editTemplate">
                        <td nzLeft="0px" nzAlign="center">
                            {{data.cd_utente}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.nome}} {{data.cognome}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <span *ngIf="data.venditore !== 'null'">{{data.venditore}}</span>
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.email}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.ruolo}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <nz-switch (click)="changeUserSwitch(data.cd_utente, 'scrittura')" [nzControl]="true" [(ngModel)]="data.scrittura">
                            </nz-switch>
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <nz-switch (click)="changeUserSwitch(data.cd_utente, 'attivo')" [nzControl]="true" [(ngModel)]="data.attivo">
                            </nz-switch>
                        </td>
                        <td nzLeft="0px" nzAlign="center" *ngIf="data.password">
                            <nz-input-group [nzSuffix]="suffixTemplate">
                                <input [type]="data.visible ? 'text' : 'password'" nz-input [(ngModel)]="data.password" class="uiAdminUserPwd" />
                            </nz-input-group>
                            <ng-template #suffixTemplate>
                                <i [ngClass]="data.visible ? 'fad fad-swap-color fa-eye-slash' : 'fad fad-swap-color fa-eye'" (click)="data.visible = !data.visible"></i>
                            </ng-template>
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.ultimo_accesso | date: 'dd/MM/yyyy HH:mm:ss'}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <i class="fad fa-edit uiAdminUserAction" (click)="startEdit(data.cd_utente)" nzTooltipTitle="{{'editUser' | translate}}" nzTooltipPlacement="top" nz-tooltip></i>
                            <br />
                            <br />
                            <i class="fad fa-trash uiAdminUserAction" (click)="deleteUser(data.cd_utente)" nzTooltipTitle="{{'deleteUser' | translate}}" nzTooltipPlacement="top" nz-tooltip></i>
                        </td>
                    </ng-container>
                    <ng-template #editTemplate>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.cd_utente}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <input class="uiUserName" type="text" nz-input [(ngModel)]="editCache[data.cd_utente].data.nome" />
                            <br />
                            <input class="uiUserName" type="text" nz-input [(ngModel)]="editCache[data.cd_utente].data.cognome" />
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <nz-select class="uiSellerSelect" nzShowSearch nzAllowClear nzPlaceHolder="{{'userSeller' | translate}}" [(ngModel)]="editCache[data.cd_utente].data.venditore">
                                <nz-option *ngFor="let seller of sellers" [nzValue]="seller.label" [nzLabel]="seller.label"></nz-option>
                            </nz-select>
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <input type="text" nz-input [(ngModel)]="editCache[data.cd_utente].data.email" />
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <nz-select [(ngModel)]="editCache[data.cd_utente].data.ruolo">
                                <nz-option nzValue="superadmin" nzLabel="superadmin"></nz-option>
                                <nz-option nzValue="admin" nzLabel="admin"></nz-option>
                                <nz-option nzValue="normale" nzLabel="normale"></nz-option>
                            </nz-select>
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <nz-switch (click)="changeUserSwitch(data.cd_utente, 'scrittura')" [nzControl]="true" [(ngModel)]="data.scrittura">
                            </nz-switch>
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <nz-switch (click)="changeUserSwitch(data.cd_utente, 'attivo')" [nzControl]="true" [(ngModel)]="data.attivo">
                            </nz-switch>
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <input [type]="text" nz-input [(ngModel)]="editCache[data.cd_utente].data.password" />
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.ultimo_accesso | date: 'dd/MM/yyyy HH:mm:ss'}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <a (click)="saveEdit(data.cd_utente)" class="save">{{'save' | translate}}</a>
                            <br />
                            <a (click)="cancelEdit(data.cd_utente)">{{'cancel' | translate}}</a>
                        </td>
                    </ng-template>
                </tr>
            </tbody>
        </nz-table>
    </nz-content>
</nz-layout>