import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor() { }

  setSelectedContact(tags: string[]) {
    sessionStorage.setItem("Contact", JSON.stringify(tags));
  }

  getSelectedContact() {
    return JSON.parse(sessionStorage.getItem("ImageTags"));
  }

  setSelectedImages(images: string[]) {
    sessionStorage.setItem("ProdImages", JSON.stringify(images));
  }

  getSelectedImages() {
    let tempImg = sessionStorage.getItem("ProdImages");
    if (tempImg) {
      return JSON.parse(tempImg);
    } else {
      return JSON.parse("[]");
    }
  }

  removeSelectedImages() {
    sessionStorage.removeItem("ProdImages");
  }

  setSelectedQuote(quote: any) {
    sessionStorage.setItem("SelectedQuote", JSON.stringify(quote));
  }

  getSelectedQuote() {
    try {
      const quote = JSON.parse(sessionStorage.getItem("SelectedQuote"));
      return quote;
    } catch (ex) {
      return null;
    }
  }

  removeSelectedQuote() {
    sessionStorage.removeItem("SelectedQuote");
  }

  setTempImages(imgs) {
    sessionStorage.setItem("TempImgsLink", JSON.stringify(imgs));
  }

  getTempImages() {
    let tempImg = sessionStorage.getItem("TempImgsLink");
    if (tempImg) {
      return JSON.parse(tempImg);
    } else {
      return JSON.parse("[]");
    }
  }

  removeTempImages() {
    sessionStorage.removeItem("TempImgsLink");
  }

  setQuoteTableIndex(page: any) {
    sessionStorage.setItem("QuoteTableIndex", JSON.stringify(page));
  }

  getQuoteTableIndex() {
    let index = JSON.parse(sessionStorage.getItem("QuoteTableIndex"));
    if (index) {
      return index;
    } else {
      return "1";
    }
  }

  removeQuoteTableIndex() {
    sessionStorage.removeItem("QuoteTableIndex");
  }
}
