import { Component, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalRef } from "ng-zorro-antd/modal";
import { TblClienti } from "src/app/entities/TblClienti";
import { TblContattiClienti } from "src/app/entities/TblContattiClienti";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: 'nz-modal-custom-component',
  templateUrl: './contactModal.component.html',
  styleUrls: ['./contactModal.component.scss']
})
export class ContactModalComponent {
  constructor(private modal: NzModalRef, private data: DataService, private message: NzMessageService, private translate: TranslateService, private auth: AuthService) { }
  @ViewChild('searchInput', { static: false }) searchInput;
  dataResult: any[];
  displayedData: any[];
  loading = true;
  expandSet = '';
  contResult: TblContattiClienti[] = [];

  ngOnInit(): void {
    let isSeller = this.auth.getIsSeller();
    if (isSeller !== 'null') {
      this.data.getCustomersBySeller(isSeller.toString()).subscribe(
        result => {
          this.dataResult = result as any[];
          this.displayedData = [
            ...this.dataResult
          ];
          this.loading = false;
        },
        error => {
          this.message.create('error', 'Errore', { nzDuration: 5000 });
          this.loading = false;
           console.error('GET TABLE: ', error);
        }
      );
    } else {
      this.data.getTable('customers').subscribe(
        result => {
          this.dataResult = result as any[];
          this.displayedData = [
            ...this.dataResult
          ];
          this.loading = false;
        },

        error => {
          this.message.create('error', 'Errore', { nzDuration: 5000 });
          this.loading = false;
           console.error('GET TABLE: ', error);
        }
      );
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.setFocus(this.searchInput);
    }, 1000);
  }

  setFocus(element) {
    if (element) {
      element.nativeElement.focus();
    }
  }

  destroyModal(): void {
    this.modal.destroy();
  }

  filter(searchValue: string) {
    this.displayedData = this.dataResult.filter((item: TblClienti) => item.descrizione.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
    item.cd_cf.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
  }

  onExpandChange(cd_cf, checked: boolean): void {
    if (checked) {
      this.data.getCustomerContacts(cd_cf).subscribe(
        result => {
          const contactsArray = result as TblContattiClienti[];
          this.contResult = contactsArray;
          this.expandSet = cd_cf;
        },
        error => {
          this.message.create('error', 'Errore', { nzDuration: 5000 });
          this.loading = false;
           console.error('GET TABLE: ', error);
        }
      );
    } else {
      this.expandSet = cd_cf;
    }
  }


  selectContact(cd, j) {
    let i = this.displayedData.findIndex(data => data.cd_cf === cd);
    const customer = this.displayedData[i];
    const contact = this.contResult[j];
    const vend = customer.nomeV ? (customer.cognomeV ? customer.nomeV + ' ' + customer.cognomeV : customer.nomeV) : '';
    const name = contact.nome ? (contact.cognome ? contact.nome + ' ' + contact.cognome : contact.nome) : '';
    const result = {'cd_cf': contact.cd_cf, 'cd_venditore': customer.codiceV, 'ragSoc': customer.descrizione, 'cVend': vend, 'indirizzo': customer.indirizzo, 'cap': customer.cap,
                    'localita': customer.localita, 'cd_provincia': customer.cd_provincia, 'codicefiscale': customer.codicefiscale, 'partitaiva': customer.partitaiva, 'attne': name, 
                    'email': contact.email, 'tipologia': customer.gruppo, 'id_contatto': contact.id};
    this.data.saveContact(result);
    this.modal.triggerOk()
    this.modal.destroy();
  }
}