<div class="uiHomeContainer">
    <div class="uiHomeInner">
        <p class="uiWelcome">{{'welcome' | translate}}, {{currentUser['nome']}} {{currentUser['cognome']}}</p>
        <div nz-row class="uiHomeBtnContainer">
            <button nz-button nzSize="large" nzType="primary" class="uiHomeBtn" type="button" (click)="newQuote()"><i class="fad fa-pencil uiAdminIcon"></i> {{'newQuote' | translate}}</button>
        </div>
        <div nz-row class="uiHomeBtnContainer">
            <button nz-button nzSize="large" nzType="primary" class="uiHomeBtn" type="button" (click)="goToHistory()"><i class="fad fad-swap-color fa-clock uiAdminIcon"></i> {{'historyQuote' | translate}}</button>
        </div>
        <div nz-row class="uiHomeBtnContainer">
            <button nz-button nzSize="large" nzType="primary" class="uiHomeBtn" type="button" (click)="goToAdmin()"><i class="fad fad-swap-color fa-cog uiAdminIcon" *ngIf="auth.isLoggedIn() && auth.getUser().ruolo !== 'utente'" (click)="goToAdmin()"></i>
                {{'administration' | translate}}
            </button>
        </div>
    </div>
</div>