import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { GlobalService } from "src/app/shared/global.service";
import { Location } from "@angular/common";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalService } from "ng-zorro-antd/modal";
import { StorageService } from "src/app/services/storage.service";
import { json } from "stream/consumers";

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.scss"],
})
export class HistoryComponent implements OnInit {
  dataResult: any[];
  displayedData: any[];
  loading = true;
  sellers: { label: string; }[] = [];
  expandSet = new Set<number>();
  listAuthors: any[] = [];
  listSellers: any[] = [];
  currentUser: any = {};
  pdfDowload = false;

  constructor(
    private g: GlobalService,
    public auth: AuthService,
    private data: DataService,
    private storage: StorageService,
    private router: Router,
    private translate: TranslateService,
    private location: Location,
    private message: NzMessageService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
    const formData = new FormData();

    this.currentUser = this.auth.getUser();
    formData.append("user", this.currentUser.cd_utente);

    this.data.getAllQuotes().subscribe(
      (result) => {
        this.dataResult = result as any[];
        this.displayedData = [...this.dataResult];
        this.loading = false;
      },
      (error) => {
        this.message.create("error", "Errore", { nzDuration: 5000 });
        this.loading = false;
        console.error("GET TABLE: ", error);
      }
    );

    this.data.getUsersName().subscribe(
      (result) => {
        this.listAuthors = result;

        this.listAuthors.forEach((name) => {
          if (name.text === this.currentUser.nome + " " + this.currentUser.cognome) {
            name.byDefault = true;
          }
        });
      },
      (error) => {
        this.message.create("error", "Errore", { nzDuration: 5000 });
        this.loading = false;
        console.error("GET TABLE: ", error);
      }
    );

    this.data.getSellersName().subscribe(
      (result) => {
        this.listSellers = result;
      },
      (error) => {
        this.message.create("error", "Errore", { nzDuration: 5000 });
        this.loading = false;
        console.error("GET TABLE: ", error);
      }
    );
  }

  /**
   * @desc after view init, set g.actualpage. Used in header.
   * @param -
   * @return void
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = "history";
      this.storage.removeSelectedQuote();
    });
  }

  back() {
    this.router.navigateByUrl("/welcome");
  }

  async openQuote(cd, createNew: boolean = false) {
    let i = this.displayedData.findIndex((data) => data.cd_preventivo === cd);
    const checkSumKey = await this.g.encryptDataGlobal(
      "TblPreventivi" + this.currentUser.cd_utente + this.currentUser.email
    );
    const formData = new FormData();
    formData.append("cf", this.currentUser.cd_utente);
    formData.append("email", this.currentUser.email);
    formData.append("cd", this.displayedData[i].cd_preventivo);
    formData.append("checkKey", checkSumKey);

    if (createNew) {
      this.data.addQuoteVersion(formData).subscribe(
        (result) => {
          this.g.saveQuote(result);
          this.router.navigateByUrl("/quote/" + result);
        },
        (error) => {
          console.error("Unable to get last: ", error);
        }
      );
    } else {
      this.router.navigateByUrl("/quote/" + this.displayedData[i].cd_preventivo);
    }
  }

  async deleteQuote(cd, v) {
    const checkSumKey = await this.g.encryptDataGlobal(
      "TblPreventivi" + this.currentUser.cd_utente + this.currentUser.email
    );
    const formData = new FormData();
    formData.append("cf", this.currentUser.cd_utente);
    formData.append("email", this.currentUser.email);
    formData.append("cd", cd);
    formData.append("versione", v);
    formData.append("checkKey", checkSumKey);

    if (!v) {
      this.data.deleteQuote(formData).subscribe(
        (result) => {
          this.dataResult = this.dataResult.filter((d) => d.cd_preventivo !== cd);
          this.displayedData = [...this.dataResult];
        },
        (error) => {
          console.error("Unable to get last: ", error);
        }
      );
    } else {
      this.data.deleteQuoteVersion(formData).subscribe(
        (result) => {
          var p = this.displayedData.find((d) => d.cd_preventivo === cd);
          if (p) {
            p.stato = "Completato";
            p.versione = String.fromCharCode(p.versione.charCodeAt(0) - 1);
          }
          //this.dataResult = this.dataResult.filter((d) => d.cd_preventivo !== cd);
          //this.displayedData = [...this.dataResult];
        },
        (error) => {
          console.error("Unable to get last: ", error);
        }
      );
    }
  }

  filter(searchValue: string = '') {

    this.displayedData = this.dataResult.filter(
      (item: any) => {
        // ***************************** 2023/07/12 Alberto Baggio ***************************************************************
        if (item.rag_soc === null) item.rag_soc = '';

        return (item.cd_preventivo.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
          item.uNome.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
          item.uCognome.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
          item.rag_soc.toLowerCase().includes(searchValue.toLocaleLowerCase()));
      }
      // *************************************************************************************************************************
    );
  }

  filterAuthor = (list: string[], item: any) => {
    const hasEmail: boolean = list.length ? list.some((value) => item.uEmail === value) : true;
    return hasEmail;
  };

  filterSeller = (list: string[], item: any) => {
    const hasEmail: boolean = list.length ? list.some((value) => item.cVendId === value) : true;
    return hasEmail;
  };

  async duplicate(cd) {
    let i = this.displayedData.findIndex((data) => data.cd_preventivo === cd);
    const checkSumKey = await this.g.encryptDataGlobal(
      "TblPreventivi" + this.currentUser.cd_utente + this.currentUser.email
    );
    const formData = new FormData();
    formData.append("cf", this.currentUser.cd_utente);
    formData.append("email", this.currentUser.email);
    formData.append("cd", this.displayedData[i].cd_preventivo);
    formData.append("version", this.displayedData[i].versione);
    formData.append("checkKey", checkSumKey);

    this.data.duplicateQuote(formData).subscribe(
      (result) => {
        this.g.saveQuote(result);
        this.router.navigateByUrl("/quote/" + result);
      },
      (error) => {
        console.error("Unable to get last: ", error);
      }
    );
  }

  /**
   * @desc create a new quote.
   *   IF THE USER HAVE NOT A "IN PROGRESS" QUOTE: save the new quote in localstorage and navigate to /quote:id
   *   OTHERWISE: show modal
   * @param -
   * @return void
   */
  async newQuote() {
    const checkSumKey = await this.g.encryptDataGlobal(
      "TblPreventivi" + this.currentUser.cd_utente + this.currentUser.email
    );
    const formData = new FormData();
    formData.append("cf", this.currentUser.cd_utente);
    formData.append("email", this.currentUser.email);
    formData.append("checkKey", checkSumKey);
    formData.append("nome", this.currentUser.nome);
    formData.append("cognome", this.currentUser.cognome);

    //get last quote id
    this.data.newQuote(formData).subscribe(
      (result) => {
        // const last = result[0].cd_preventivo + 1;
        this.g.saveQuote(result);
        this.router.navigateByUrl("/quote/" + result);
      },
      (error) => {
        console.error("Unable to get last: ", error);
      }
    );
  }

  openPdf(cd) {
    let i = this.displayedData.findIndex((data) => data.cd_preventivo === cd);
    const formData = new FormData();
    formData.append("cd", this.displayedData[i].cd_preventivo);
    formData.append("versione", this.displayedData[i].versione);
    this.data.generatePdf(formData).subscribe(
      (result) => {
        window.open(this.g.pdfPath + result.nome, "_blank");
      },
      (error) => {
        console.error("Unable to generate pdf: ", error);
      }
    );
  }

  downloadPdf(cd) {
    this.pdfDowload = true;
    let i = this.displayedData.findIndex((data) => data.cd_preventivo === cd);
    const formData = new FormData();
    formData.append("cd", this.displayedData[i].cd_preventivo);
    formData.append("versione", this.displayedData[i].versione);
    this.data.generatePdf(formData).subscribe(
      async (result) => {
        this.pdfDowload = false;
        const base64Response = await fetch(result.file);
        const blob = await base64Response.blob();
        const fileURL = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = fileURL;
        a.download = result.nome;
        a.target = "_blank";
        a.click();
      },
      (error) => {
        this.pdfDowload = false;
        console.error("Unable to generate pdf: ", error);
      }
    );
  }

  exportExcel(data) {

    // ******************* 2023/06/30 Alberto Baggio ***************************************************************
    // seleziono a che versione sono arrivato
    const allVersion = 'abcdefghijklmnopqrstuvwxyz';
    let myVersion = allVersion.substring(0, allVersion.indexOf(data.versione) + 1);

    const formData = new FormData();
    formData.append("cd_preventivo", data.cd_preventivo);

    // creo tanti file qante verioni ho!!
    for (let i = 0; i < myVersion.length; i++) {
      formData.delete("versione");
      formData.append("versione", myVersion[i]);
      this.data.exportExcel(formData).subscribe(
        async (result) => {
          const a = document.createElement("a");
          const blob = new Blob([result], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);

          a.href = url;
          a.download = data.cd_preventivo + "v" + myVersion[i] + ".csv";
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        },
        (error) => {
          console.error("Unable to export excel csv: ", error);
        }
      );
    }

    // *****************************************************************************************************************
  }
}
