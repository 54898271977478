import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DataService } from "src/app/services/data.service";
import { GlobalService } from "src/app/shared/global.service";
import { NzFormatEmitEvent, NzTreeNode } from "ng-zorro-antd/tree";
import { NzMessageService } from "ng-zorro-antd/message";
import { TblProdotti } from "src/app/entities/TblProdotti";
import { Location } from "@angular/common";
import { SwiperComponent } from "swiper/angular";
import { DomSanitizer } from "@angular/platform-browser";
// import Swiper core and required components
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
} from "swiper/core";
import { NzModalService } from "ng-zorro-antd/modal";
import { ImgModalComponent } from "./imgModal/imgModal.component";
import { StorageService } from "src/app/services/storage.service";

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Virtual, Zoom, Autoplay, Thumbs, Controller]);

@Component({
  selector: "app-add",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.scss"],
})
export class AddComponent implements OnInit {
  searchValue = "";
  searchString = "";
  categories: any[];
  loading = true;
  visible = true;
  selectedProd = "";
  product: any;
  selectedImgs: any[] = [];
  fornitore = false;
  totQuote = 0;
  totQta = 0;

  constructor(
    public translate: TranslateService,
    private g: GlobalService,
    private data: DataService,
    private message: NzMessageService,
    private location: Location,
    private sanitizer: DomSanitizer,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private storage: StorageService
  ) { }
  @ViewChild("treeNodes") tree;
  @ViewChild("searchInput", { static: false }) searchInput;

  ngOnInit(): void {
    this.data.getTable("categories").subscribe(
      (result) => {
        this.categories = result;
        this.loading = false;
        this.categories[this.categories.length] = {
          key: "00.00",
          title: "Riga vuota",
          isLeaf: true,
          selectable: true,
          expanded: false,
          type: "product",
        };
        const storedQuote = this.storage.getSelectedQuote();
        this.totQuote = storedQuote.total;
        this.totQta = storedQuote.qta;
      },
      (error) => {
        this.message.create("error", "Errore", { nzDuration: 5000 });
        this.loading = false;
        console.error("GET TABLE: ", error);
      }
    );
  }

  /**
   * @desc after view init, set g.actualpage. Used in header.
   * @param -
   * @return void
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = this.translate.instant("addProdToQuote");
    });
    setTimeout(() => {
      this.setFocus(this.searchInput);
    }, 1000);
  }

  setFocus(element) {
    if (element) {
      element.nativeElement.focus();
    }
  }

  async openFolder(data) {
    const node = data.node;
    if (!node.expanded) {
      node.expanded = true;
      if (node.origin.type === "family") {
        const id = node.key;
        const checkSumKey = await this.g.encryptDataGlobal("TblProdotti" + id);
        const formData = new FormData();
        formData.append("cd", id);
        formData.append("checkKey", checkSumKey);
        this.data.getProductsByFam(formData).subscribe(
          (result) => {
            if (node?.getChildren().length === 0 && node?.isExpanded) {
              node.addChildren(result);
            }
          },
          (error) => {
            this.message.create("error", "Errore", { nzDuration: 5000 });
            console.error("GET TABLE: ", error);
          }
        );
      }
    } else {
      node.expanded = false;
    }
  }

  activeNode(data: NzFormatEmitEvent): void {
    if (data.node.origin.type === "product") {
      this.selectedProd = data.node.key;
      this.insertProd();
    }
  }

  async search(event) {
    if (this.searchValue.length > 2) {
      const search = this.searchValue;
      const checkSumKey = await this.g.encryptDataGlobal("TblProdotti" + search);
      const formData = new FormData();
      formData.append("search", search);
      formData.append("checkKey", checkSumKey);
      this.data.getProductsByString(formData).subscribe(
        (result: any[]) => {
          result.forEach((res) => {
            const cat: NzTreeNode = this.tree.getTreeNodeByKey(res.cd_cat);
            if (cat) {
              const catCh: NzTreeNode[] = cat.getChildren();
              const famIndex = catCh.findIndex((el) => el.key === res.cd_fam);
              const fam = catCh[famIndex];
              if (famIndex >= 0) {
                const actualChildrens = fam.getChildren();
                const alreadyExixtsChild = actualChildrens.findIndex((el) => el.key === res.key);
                if (alreadyExixtsChild >= 0) {
                } else {
                  const resArray: any[] = [];
                  resArray[0] = res;
                  fam.addChildren(resArray);
                }
                fam.setExpanded(true);
                cat.setExpanded(true);
              }
            }
          });
        },
        (error) => {
          this.message.create("error", "Errore", { nzDuration: 5000 });
          console.error("GET TABLE: ", error);
        }
      );
    } else {
      const expanded: NzTreeNode[] = this.tree.getExpandedNodeList();
      console.log(expanded);
      expanded.forEach((exp) => {
        exp.setExpanded(false);
      });
    }
  }

  openDrawer() {
    this.visible = true;
  }

  async insertProd() {
    if (this.selectedProd !== "00.00") {
      const cd = this.selectedProd;
      const checkSumKey = await this.g.encryptDataGlobal("TblProdotti" + cd);
      const formData = new FormData();
      formData.append("cd", cd);
      formData.append("checkKey", checkSumKey);
      this.data.getProductById(formData).subscribe(
        (result) => {
          this.product = result[0] as TblProdotti;
          this.visible = false;
          this.storage.setSelectedImages([]);
          this.selectedImgs = [];
          if (this.product.pr_fornitore > 0) {
            this.fornitore = true;
          } else {
            this.fornitore = false;
          }
        },
        (error) => {
          this.message.create("error", "Errore", { nzDuration: 5000 });
          console.error("GET TABLE: ", error);
        }
      );
    } else {
      this.product = {
        cd_riga: null,
        cd_prodotto: "00.00",
        nome: "",
        descrizione: "",
        cd_fam: "",
        note: "",
        note_interne: "",
        um: "nr",
        quantita: 0,
        pr_fornitore: 0,
        sc_materiale: 0,
        sc_extra: 0,
        unit_materiale: 0,
        unit_manodopera: 0,
        perc_manodopera: 0,
        ricarico: 0.0,
        immagini: [],
      };
      this.fornitore = false;
      this.visible = false;
      this.storage.setSelectedImages([]);
      this.selectedImgs = [];
    }
  }

  async saveInsert() {
    const selectedQuote = this.storage.getSelectedQuote();

    if (this.product.descrizione) this.product.descrizione = this.product.descrizione.replace(/<img[^>]*>/g, "");
    if (this.product.note) this.product.note = this.product.note.replace(/<img[^>]*>/g, "");

    let insert = {
      cd_preventivo: selectedQuote.id,
      versione: selectedQuote.version,
      cd_prodotto: this.product.cd_prodotto,
      nome: this.product.nome,
      descrizione: this.product.descrizione,
      cd_fam: this.product.cd_fam,
      note: this.product.note,
      note_interne: this.product.note_interne,
      um: this.product.um,
      quantita: this.product.quantita,
      perc_manodopera: this.product.perc_manodopera,
      sc_materiale: this.product.sc_materiale,
      sc_extra: this.product.sc_extra,
      unit_manodopera: this.product.unit_manodopera,
      unit_materiale: this.product.unit_materiale,
      pr_fornitore: this.product.pr_fornitore,
      ricarico: this.product.ricarico,
      immagini: this.storage.getSelectedImages(),
      ordine: 999,
    };

    const jsonInsert = JSON.stringify(insert);

    const checkSumKey = await this.g.encryptDataGlobal("TblPreventivi" + jsonInsert);
    const formData = new FormData();
    formData.append("insert", jsonInsert);
    formData.append("checkKey", checkSumKey);

    this.data.addQuoteRow(formData).subscribe(
      (result) => {
        this.storage.removeSelectedImages();
        this.storage.removeTempImages();
        this.storage.setQuoteTableIndex("add");
        this.location.back();
      },
      (error) => {
        this.message.create("error", "Errore", { nzDuration: 5000 });
        console.error("GET TABLE: ", error);
      }
    );
  }

  back() {
    this.storage.removeSelectedImages();
    this.storage.removeTempImages();
    this.location.back();
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  openImageModal() {
    const modal = this.modal.create({
      nzTitle: "Scegli immagini",
      nzContent: ImgModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzClassName: "uiImgModal",
      nzComponentParams: {
        cd_prodotto: this.product.cd_prodotto,
      },
      nzOnOk: () => {
        this.selectedImgs = this.storage.getSelectedImages();
      },
    });
  }

  calculateMan(e) {
    this.product.unit_manodopera = this.product.pr_fornitore * (this.product.perc_manodopera / 100);
  }

  calculateMat(e) {
    this.product.unit_materiale =
      this.product.pr_fornitore - (this.product.pr_fornitore * this.product.sc_materiale) / 100;
    this.product.unit_materiale =
      this.product.unit_materiale - (this.product.unit_materiale * this.product.sc_extra) / 100;
  }

  toFloat(value: string) {
    return Number(parseFloat(value)).toFixed(2);
  }

  formatterPercent = (value: number) => `${value} %`;
  parserPercent = (value: string) => value.replace(" %", "");

  enableList() {
    this.fornitore = !this.fornitore;
    this.product.unit_materiale = this.toFloat(this.product.unit_materiale);
    this.product.unit_manodopera = this.toFloat(this.product.unit_manodopera);
  }
}
