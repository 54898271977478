import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/shared/global.service';
import { NzFormatEmitEvent, NzTreeNode } from 'ng-zorro-antd/tree';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TblProdotti } from 'src/app/entities/TblProdotti';
import { Location } from '@angular/common';
import { SwiperComponent } from 'swiper/angular';
import { DomSanitizer } from '@angular/platform-browser';
// import Swiper core and required components
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
} from 'swiper/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ImgEditProductModalComponent } from './imgModal/imgModal.component';
import { StorageService } from 'src/app/services/storage.service';
import { ActivatedRoute } from '@angular/router';

// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
]);

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss'],
})
export class ProductEditComponent implements OnInit {
  prodId: string = '';
  product: any;
  visible = false;
  selectedImgs: any[] = [];
  fornitore = false;
  searchValue = '';
  searchString = '';
  categories: any[];
  loading = true;
  selectedProd = '';
  totQuote = 0;
  selectedCat: any;
  selectedFam = '';

  constructor(
    private route: ActivatedRoute,
    private data: DataService,
    private storage: StorageService,
    private g: GlobalService,
    private message: NzMessageService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private location: Location
  ) {}

  @ViewChild('treeNodes') tree;
  @ViewChild('catSelect') catSelect;
  @ViewChild('famSelect') famSelect;

  ngOnInit() {
    this.data.getTable('categories').subscribe(
      (result) => {
        this.categories = result;
        this.loading = false;
        this.route.params.subscribe((param) => {
          this.prodId = param.id;
          this.initProd();
        });
      },
      (error) => {
        this.message.create('error', 'Errore', { nzDuration: 5000 });
        this.loading = false;
        console.error('GET TABLE: ', error);
      }
    );
  }

  async initProd() {
    const cd = this.prodId;
    const checkSumKey = await this.g.encryptDataGlobal(
      'TblProdotti' + cd.toString()
    );
    const formData = new FormData();
    formData.append('cd', cd);
    formData.append('checkKey', checkSumKey);

    this.data.getProductById(formData).subscribe(
      (result) => {
        this.product = result[0];
        this.visible = false;
        this.selectedImgs =
          this.product.immagini != 'null'
            ? JSON.parse(this.product.immagini)
            : '';
        this.storage.setSelectedImages(this.selectedImgs);
        if (this.product.pr_fornitore > 0) {
          this.fornitore = true;
        } else {
          this.fornitore = false;
        }

        const current_cat = this.categories.find((cat) =>
          cat.children.some((item) => item.key === this.product.cd_fam)
        );

        if (current_cat) {
          this.selectedCat = current_cat;
          this.selectedFam = this.product.cd_fam;
        }
      },
      (error) => {
        this.message.create('error', 'Errore', { nzDuration: 5000 });
        console.error('GET TABLE: ', error);
      }
    );
  }

  /**
   * @desc after view init, set g.actualpage. Used in header.
   * @param -
   * @return void
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.g.actualPage = this.translate.instant('addProd');
    });
  }

  async saveInsert() {
    let insert = {
      cd_prodotto: this.prodId,
      nome: this.product.nome,
      descrizione: this.product.descrizione,
      cd_fam: this.product.cd_fam,
      note: this.product.note,
      note_interne: this.product.note_interne,
      um: this.product.um,
      quantita: this.product.quantita,
      perc_manodopera: this.product.perc_manodopera,
      sc_materiale: this.product.sc_materiale,
      sc_extra: this.product.sc_extra,
      unit_manodopera: this.product.unit_manodopera,
      unit_materiale: this.product.unit_materiale,
      pr_fornitore: this.product.pr_fornitore,
      ricarico: this.product.ricarico,
      immagini: this.storage.getSelectedImages(),
    };

    const jsonInsert = JSON.stringify(insert);

    const checkSumKey = await this.g.encryptDataGlobal(
      'TblProdotti' + jsonInsert
    );
    const formData = new FormData();
    formData.append('insert', jsonInsert);
    formData.append('checkKey', checkSumKey);

    this.data.editProduct(formData).subscribe(
      (result) => {
        this.location.back();
      },
      (error) => {
        this.message.create('error', 'Errore', { nzDuration: 5000 });
        console.error('GET TABLE: ', error);
      }
    );
  }

  back() {
    this.location.back();
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  openImageModal() {
    const modal = this.modal.create({
      nzTitle: 'Scegli immagini',
      nzContent: ImgEditProductModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzClassName: 'uiImgModal',
      nzComponentParams: {
        cd_prodotto: this.product.cd_prodotto,
      },
      nzOnOk: () => {
        this.selectedImgs = this.storage.getSelectedImages();
      },
    });
  }

  calculateMan(e) {
    this.product.unit_manodopera =
      this.product.pr_fornitore * (this.product.perc_manodopera / 100);
  }

  calculateMat(e) {
    this.product.unit_materiale =
      this.product.pr_fornitore -
      (this.product.pr_fornitore * this.product.sc_materiale) / 100;
    this.product.unit_materiale =
      this.product.unit_materiale -
      (this.product.unit_materiale * this.product.sc_extra) / 100;
  }

  toFloat(value: string) {
    return Number(parseFloat(value)).toFixed(2);
  }

  formatterPercent = (value: number) => `${value} %`;
  parserPercent = (value: string) => value.replace(' %', '');

  enableList() {
    this.fornitore = !this.fornitore;
    this.product.unit_materiale = this.toFloat(this.product.unit_materiale);
    this.product.unit_manodopera = this.toFloat(this.product.unit_manodopera);
  }

  openDrawer() {
    this.visible = true;
  }

  catChanged($event) {
    this.selectedFam = '';
    if (this.famSelect) {
      this.famSelect.focus();
      this.famSelect.nzOpen = true;
    }
  }

  famChanged($event) {
    this.product.cd_fam = this.selectedFam;
  }
}
