import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DataService } from "src/app/services/data.service";
import { GlobalService } from "src/app/shared/global.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalService } from "ng-zorro-antd/modal";
import { NzTreeHigherOrderServiceToken } from "ng-zorro-antd/core/tree";

@Component({
	selector: "app-categories",
	templateUrl: "./categories.component.html",
	styleUrls: ["./categories.component.scss"],
})
export class CategoriesComponent implements OnInit {
	dataResult: any;
	displayedData: any[];
	loading = true;

	backupObj: any = null;

	constructor(
		private data: DataService,
		private g: GlobalService,
		private message: NzMessageService,
		private translate: TranslateService,
		private modal: NzModalService,
		private changeDetectorRef: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.data.getTable("categories").subscribe(
			(result) => {
				result.forEach((rec) => {
					rec["expand"] = false;
				});
				this.g.log(result);
				this.dataResult = result;
				this.displayedData = [...this.dataResult];
				this.loading = false;
			},
			(error) => {
				this.message.create("error", "Errore", { nzDuration: 5000 });
				this.loading = false;
				console.error("GET TABLE: ", error);
			}
		);
	}

	addCat() {
		this.backupObj = {
			key: "",
			title: "",
			edit: true,
			expand: false,
			children: [],
		};
		this.displayedData = [this.backupObj, ...this.dataResult];
	}
	editCat(cat) {
		//const cat = this.dataResult.find((cat) => cat.key == key);
		if (cat) {
			this.backupObj = { ...cat };
			cat.expand = false;
			cat.edit = true;
		}
	}
	saveCat(cat) {
		this.loading = true;
		const record = { cd_cat: cat.key, nome: cat.title };
		const jsonRecord = JSON.stringify(record);
		this.g
			.encryptDataGlobal("TblCategorie" + jsonRecord)
			.then((checkSumKey) => {
				const formData = new FormData();
				formData.append("insert", jsonRecord);
				formData.append("checkKey", checkSumKey);

				if (cat.key == "") {
					this.data.edit(formData, "/categories/addCategory.php").subscribe(
						(result) => {
							this.backupObj = null;
							cat.edit = false;
							cat.key = result;
						},
						(error) => {
							this.message.create("error", "Errore", { nzDuration: 5000 });
							console.error("ADD CAT: ", error);
						}
					);
				} else {
					this.data.edit(formData, "/categories/editCategory.php").subscribe(
						(result) => {
							this.backupObj = null;
							cat.edit = false;
						},
						(error) => {
							this.message.create("error", "Errore", { nzDuration: 5000 });
							console.error("SAVE CAT: ", error);
						}
					);
				}
			})
			.finally(() => {
				this.loading = false;
			});
	}
	deleteCat(cat) {
		if (cat.children.length > 0) {
			this.message.create("error", this.translate.instant("msgNoDelete"), {
				nzDuration: 5000,
			});
			return;
		}
		this.loading = true;
		const record = {
			cd_cat: cat.key,
		};
		const jsonRecord = JSON.stringify(record);
		this.g
			.encryptDataGlobal("TblCategorie" + jsonRecord)
			.then((checkSumKey) => {
				const formData = new FormData();
				formData.append("jsonRecord", jsonRecord);
				formData.append("checkKey", checkSumKey);

				this.data.edit(formData, "/categories/deleteCategory.php").subscribe(
					(result) => {
						this.message.create("info", this.translate.instant("msgComplete"), {
							nzDuration: 5000,
						});
						this.displayedData = this.displayedData.filter((c) => c !== cat);
						this.displayedData = [...this.displayedData];
					},
					(error) => {
						this.message.create("error", "Errore", { nzDuration: 5000 });
						console.error("SAVE CAT: ", error);
					}
				);
			})
			.finally(() => {
				this.loading = false;
			});
	}
	undoCat(cat) {
		if (this.backupObj.key == "") {
			this.displayedData.shift();
			this.displayedData = [...this.displayedData];
		} else if (cat) {
			cat.title = this.backupObj.title;
			cat.edit = false;
		}
		this.backupObj = null;
	}

	addFam(cat) {
		this.backupObj = {
			key: "",
			cd_cat: cat.key,
			title: "",
			edit: true,
		};
		cat.children = [this.backupObj, ...cat.children];
	}
	editFam(fam) {
		if (fam) {
			fam.edit = true;
			this.backupObj = { ...fam };
		}
	}
	saveFam(fam) {
		this.loading = true;
		const record = { cd_fam: fam.key, cd_cat: fam.cd_cat, nome: fam.title };
		const jsonRecord = JSON.stringify(record);
		this.g
			.encryptDataGlobal("TblFamiglie" + jsonRecord)
			.then((checkSumKey) => {
				const formData = new FormData();
				formData.append("insert", jsonRecord);
				formData.append("checkKey", checkSumKey);

				if (fam.key == "") {
					this.data.edit(formData, "/categories/addFamily.php").subscribe(
						(result) => {
							this.backupObj = null;
							fam.edit = false;
							fam.key = result;
						},
						(error) => {
							this.message.create("error", "Errore", { nzDuration: 5000 });
							console.error("ADD CAT: ", error);
						}
					);
				} else {
					this.data.edit(formData, "/categories/editFamily.php").subscribe(
						(result) => {
							this.backupObj = null;
							fam.edit = false;
						},
						(error) => {
							this.message.create("error", this.translate.instant("msgError"), {
								nzDuration: 5000,
							});
							console.error("SAVE FAM: ", error);
						}
					);
				}
			})
			.finally(() => {
				this.loading = false;
			});
	}
	deleteFam(cat, fam) {
		this.loading = true;
		const record = {
			cd_fam: fam.key,
			cd_cat: fam.cd_cat,
		};
		const jsonRecord = JSON.stringify(record);
		this.g
			.encryptDataGlobal("TblFamiglie" + jsonRecord)
			.then((checkSumKey) => {
				const formData = new FormData();
				formData.append("jsonRecord", jsonRecord);
				formData.append("checkKey", checkSumKey);

				this.data.edit(formData, "/categories/deleteFamily.php").subscribe(
					(result) => {
						this.message.create("info", this.translate.instant("msgComplete"), {
							nzDuration: 5000,
						});
						cat.children = cat.children.filter((f) => f.key !== fam.key);
						cat.children = [...cat.children];
					},
					(error) => {
						this.message.create("error", this.translate.instant("msgError"), {
							nzDuration: 5000,
						});
						console.error("DELETE FAM: ", error);
					}
				);
			})
			.finally(() => {
				this.loading = false;
			});
	}
	undoFam(cat, fam) {
		if (this.backupObj.key == "") {
			cat.children.shift();
			cat.children = [...cat.children];
		} else if (fam) {
			fam.title = this.backupObj.title;
			fam.edit = false;
		}
		this.backupObj = null;
	}
}
