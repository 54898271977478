import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { GlobalService } from "src/app/shared/global.service";

@Component({
	selector: "app-welcome",
	templateUrl: "./welcome.component.html",
	styleUrls: ["./welcome.component.scss"],
})
export class WelcomeComponent implements OnInit {
	showModal = false;
	currentUser = {};

	constructor(
		private g: GlobalService,
		public auth: AuthService,
		private data: DataService,
		private router: Router,
		private translate: TranslateService
	) {}

	ngOnInit() {
		this.currentUser = this.auth.getUser();
		console.log(this.currentUser);
	}

	/**
	 * @desc after view init, set g.actualpage. Used in header.
	 * @param -
	 * @return void
	 */
	ngAfterViewInit() {
		setTimeout(() => {
			this.g.actualPage = this.translate.instant("homepage");
		});
	}

	/**
	 * @desc create a new quote.
	 *   IF THE USER HAVE NOT A "IN PROGRESS" QUOTE: save the new quote in localstorage and navigate to /quote:id
	 *   OTHERWISE: show modal
	 * @param -
	 * @return void
	 */
	async newQuote() {
		const checkSumKey = await this.g.encryptDataGlobal(
			"TblPreventivi" + this.auth.getUser().cd_utente + this.auth.getUser().email
		);
		const formData = new FormData();
		formData.append("cf", this.auth.getUser().cd_utente);
		formData.append("email", this.auth.getUser().email);
		formData.append("checkKey", checkSumKey);
		//get last quote id
		this.data.newQuote(formData).subscribe(
			(result) => {
				console.log(result);
				// const last = result[0].cd_preventivo + 1;
				this.g.saveQuote(result);
				this.router.navigateByUrl("/quote/" + result);
			},
			(error) => {
				console.error("Unable to get last: ", error);
			}
		);
	}

	/**
	 * @desc the user have a "IN PROGRESS" quote and want to show it.
	 *       navigate to /quote:id
	 * @param -
	 * @return void
	 */
	goToHistory() {
		this.router.navigateByUrl("/history");
	}

	goToAdmin() {
		this.router.navigateByUrl("/admin");
	}
}
