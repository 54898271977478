import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "./services/auth.service";
import { DataService } from "./services/data.service";
import { GlobalService } from "./shared/global.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent {
	title = "tecnostile-quote";
	//currentUser = { nome: "", cognome: "" };

	constructor(
		public g: GlobalService,
		public auth: AuthService,
		public translate: TranslateService,
		public data: DataService,
		private router: Router
	) {
		translate.addLangs(["en", "it"]);
		translate.setDefaultLang("it");
		translate.use("it");

		this.data.getKey().subscribe(
			(result) => {
				for (let i = 0; i < result.length; i++) {
					this.g.saveKey(result[i].key);
				}
			},
			(error) => {
				console.error("GET TABLE: ", error);
			}
		);

		var tempUser = this.auth.getUser();
		this.g.loggedUser = tempUser ? tempUser : { nome: "", cognome: "" };
	}

	/**
	 * @desc change system language
	 * @param language - the system language
	 * @return void
	 */
	useLanguage(language: string) {
		this.translate.use(language);
		this.g.selectedLanguage = language;
	}

	/**
	 * @desc navigate to location
	 * @param location - the location to navigate on
	 * @return void
	 */
	goTo(location: string) {
		this.router.navigateByUrl(location);
	}
}
