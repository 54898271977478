<nz-layout class="uiUserAdminInnerLayout">
  <nz-content>
    <nz-table #dataTable nzBordered nzSize="large" [nzData]="displayedData" [nzLoading]="loading" [nzFrontPagination]="false" [nzSimple]="true" class="uiAdminTable">
      <thead>
        <tr>
          <th nzWidth="30px"></th>
          <th nzAlign="center" nzWidth="50px">{{'catId' | translate}}</th>
          <th nzAlign="center">{{'catName' | translate}}</th>
          <th nzAlign="center" nzWidth="80px">
            <a class="uiAdminAddBtn" style="font-size: large" [attr.disabled]="backupObj" (click)="addCat()" nzTooltipTitle="{{'toottipAdd' | translate}}" nzTooltipPlacement="top" nz-tooltip nz-button>
              <i class="fa fa-plus-square"></i>
            </a>
          </th>
        </tr>
      </thead>
      <tbody class="uiAdminTableBody">
        <ng-container *ngFor="let data of dataTable.data; let i = index">
          <tr [ngClass]="i%2 !== 0 ? 'uiColoredRow' : ''">
            <ng-container *ngIf="!data.edit; else editCatTemplate">
              <td [(nzExpand)]="data.expand"></td>
              <td nzAlign="center">
                {{data.key}}
              </td>
              <td>
                {{data.title}}
              </td>
              <td style="display: flex; justify-content: space-around">
                <a class="uiAdminAddBtn" [attr.disabled]="backupObj" (click)="editCat(data)" nzTooltipTitle="{{'tooltip.edit' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-edit uiAdminUserAction"></i>
                </a>
                <a class="uiAdminAddBtn" nz-popconfirm nzPopconfirmTitle="{{ 'alertDelete' | translate }}" nzPopconfirmPlacement="top" (nzOnConfirm)="deleteCat(data)" [attr.disabled]="backupObj" nzTooltipTitle="{{'tooltip.delete' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-trash uiAdminUserAction"></i>
                </a>
              </td>
            </ng-container>
            <ng-template #editCatTemplate>
              <td nzExpand="false" nzShowExpand="false"></td>
              <td nzAlign="center">
                {{data.key}}
              </td>
              <td>
                <input type="text" nz-input [(ngModel)]="data.title" />
              </td>
              <td style="display: flex; justify-content: space-around">
                <a class="uiAdminAddBtn" (click)="saveCat(data)" nzTooltipTitle="{{'tooltip.save' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-save uiAdminUserAction"></i>
                </a>
                <a class="uiAdminAddBtn" (click)="undoCat(data)" nzTooltipTitle="{{'tooltip.cancel' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-undo uiAdminUserAction"></i>
                </a>
              </td>
            </ng-template>
          </tr>
          <tr [nzExpand]="data.expand">
            <nz-table #innerTable clsss="uiInnerTableBody" [nzFrontPagination]="false" [nzData]="data.children" nzSize="middle" [nzShowPagination]="false">
              <thead>
                <tr>
                  <th nzAlign="center" nzWidth="50px">{{'famId' | translate}}</th>
                  <th nzAlign="center">{{'famName' | translate}}</th>
                  <th nzAlign="center" nzWidth="80px">
                    <a class="uiAdminAddBtn" style="font-size: large" [attr.disabled]="backupObj" (click)="addFam(data)" nzTooltipTitle="{{'toottipAdd' | translate}}" nzTooltipPlacement="top" nz-tooltip nz-button>
                      <i class="fa fa-plus-square"></i>
                    </a>
                  </th>
                </tr>
              </thead>

      <tbody class="uiAdminTableBody">
        <ng-container *ngFor="let innerData of innerTable.data; let iI = index">
          <tr>
            <ng-container *ngIf="!innerData.edit; else editFamTemplate">
              <td nzAlign="center">{{ innerData.key }}</td>
              <td>{{ innerData.title }}</td>
              <td style="display: flex; justify-content: space-around">
                <a class="uiAdminAddBtn" [attr.disabled]="backupObj" (click)="editFam(innerData)" nzTooltipTitle="{{'tooltip.edit' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-edit uiAdminUserAction"></i>
                </a>
                <a class="uiAdminAddBtn" [attr.disabled]="backupObj" nz-popconfirm nzPopconfirmTitle="{{ 'alertDelete' | translate }}" nzPopconfirmPlacement="top" (nzOnConfirm)="deleteFam(data, innerData)" nzTooltipTitle="{{'tooltipDelete' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-trash uiAdminUserAction"></i>
                </a>
              </td>
            </ng-container>
            <ng-template #editFamTemplate>
              <td nzAlign="center">
                {{innerData.key}}
              </td>
              <td>
                <input type="text" nz-input [(ngModel)]="innerData.title" />
              </td>
              <td style="display: flex; justify-content: space-around">
                <a class="uiAdminAddBtn" (click)="saveFam(innerData)" nzTooltipTitle="{{'tooltip.save' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-save uiAdminUserAction"></i>
                </a>
                <a class="uiAdminAddBtn" (click)="undoFam(data, innerData)" nzTooltipTitle="{{'tooltip.cancel' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                  <i class="fad fa-undo uiAdminUserAction"></i>
                </a>
              </td>
            </ng-template>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
    </tr>
    </ng-container>
    </tbody>
    </nz-table>


  </nz-content>
</nz-layout>