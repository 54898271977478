<nz-layout class="uiUserAdminInnerLayout">
    <nz-content>
        <div class="uiAdminAddContainer">
            <button nz-button nzType="primary" class="uiAdminAddBtn" (click)="showModal()">
                <i class="fad fa-plus-square"></i>
                {{'custProvAdd' | translate}}
            </button>
            <nz-modal [(nzVisible)]="showForm" nzTitle="{{'custProvAdd' | translate}}" nzFooter="null" (nzOnCancel)="handleCancel()" class="uiCustomerModal">
                <ng-container *nzModalContent>
                    <button nz-button nzType="primary" class="uiModalAddBtn" (click)="newTab()" nzTooltipPlacement="top" nz-tooltip>{{'addContact' | translate}}</button>

                    <nz-tabset nzType="editable-card" class="uiAddCustomersTab" nzHideAdd (nzClose)="closeTab($event)" [nzAddIcon]="plus">
                        <nz-tab nzTitle="Cliente">
                            <form [formGroup]="custForm" class="uiAddForm">
                                <p>ID: {{lastP}}</p>
                                <input nz-input formControlName="descrizione" placeholder="{{'custSoc' | translate}}*" />
                                <input nz-input formControlName="indirizzo" placeholder="{{'custAdress' | translate}}*" />
                                <input nz-input formControlName="localita" placeholder="{{'custLoc' | translate}}*" />
                                <input nz-input formControlName="cap" placeholder="{{'custCap' | translate}}*" />
                                <input nz-input formControlName="cd_nazione" placeholder="{{'custNaz' | translate}}*" />
                                <input nz-input formControlName="cd_provincia" placeholder="{{'custProv' | translate}}*" />
                                <input nz-input formControlName="piva" placeholder="{{'custIva' | translate}}*" />
                                <input nz-input formControlName="cf" placeholder="{{'custCF' | translate}}*" />
                                <nz-select nzShowSearch nzAllowClear formControlName="venditore" nzPlaceHolder="{{'custSeller' | translate}}">
                                    <nz-option *ngFor="let seller of sellers" [nzValue]="seller.label" [nzLabel]="seller.label">
                                    </nz-option>
                                </nz-select>
                                <nz-select nzShowSearch nzAllowClear formControlName="gruppo" nzPlaceHolder="{{'custGroup' | translate}}*">
                                    <nz-option *ngFor="let g of groups" [nzValue]="g.label" [nzLabel]="g.nome">
                                    </nz-option>
                                </nz-select>
                                <textarea nz-input formControlName="note" placeholder="{{'custNote' | translate}}"></textarea>
                            </form>
                        </nz-tab>
                        <nz-tab *ngFor="let tab of tabs; let i = index" [nzTitle]="tab" nzClosable>
                            <form [formGroup]="contactForm" class="uiAddForm">
                                <div formArrayName="contacts">
                                    <div [formGroupName]="i">
                                        <input nz-input formControlName="nome" placeholder="{{'custName' | translate}}*" />
                                        <input nz-input formControlName="cognome" placeholder="{{'custSurname' | translate}}" />
                                        <input nz-input formControlName="titolo" placeholder="{{'custTitle' | translate}}" />
                                        <input nz-input formControlName="tel" placeholder="{{'custTel' | translate}}" />
                                        <input nz-input formControlName="tel2" placeholder="{{'custTel2' | translate}}" />
                                        <input nz-input formControlName="cell" placeholder="{{'custCell' | translate}}" />
                                        <input nz-input formControlName="cell2" placeholder="{{'custCell2' | translate}}" />
                                        <input nz-input formControlName="email" placeholder="{{'custEmail' | translate}}" />
                                        <input nz-input formControlName="pec" placeholder="{{'custPec' | translate}}" />
                                        <input nz-input formControlName="url" placeholder="{{'custUrl' | translate}}" />
                                        <input nz-input formControlName="note" placeholder="{{'custNote' | translate}}" />
                                    </div>
                                </div>
                            </form>
                        </nz-tab>
                    </nz-tabset>
                </ng-container>
                <div *nzModalFooter>
                    <button nz-button nzType="primary" class="uiModalBackBtn" (click)="handleCancel()">
                        {{'cancel' | translate}}
                    </button>
                    <button nz-button nzType="primary" [disabled]="custForm.invalid || contactForm.invalid" class="uiModalAddBtn" (click)="addCustomer()" nzTooltipTitle="{{'tooltip.add' | translate}}" nzTooltipPlacement="top" nz-tooltip>{{'tooltip.add' | translate}}</button>
                </div>
            </nz-modal>
        </div>
        <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input placeholder="Ricerca" (input)="filter($event.target.value)" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
            <i class="fad fa-search"></i>
        </ng-template>
        <nz-table #dataTable nzBordered nzSize="middle" [nzData]="displayedData" [nzPageSize]="30" [nzLoading]="loading" nzPaginationPosition="both" [nzSimple]="true" class="uiAdminTable">
            <thead>
                <tr>
                    <th nzWidth="50px"></th>
                    <th nzAlign="center" nzWidth="50px">Id</th>
                    <th nzAlign="center" nzWidth="400px">{{'custSoc' | translate}}</th>
                    <th nzAlign="center">{{'custAdress' | translate}}</th>
                    <th nzAlign="center" nzWidth="80px">{{'custIva' | translate}}</th>
                    <th nzAlign="center" nzWidth="80px">{{'custCF' | translate}}</th>
                    <th nzAlign="center" nzWidth="80px">{{'custGroup' | translate}}</th>
                    <th nzAlign="center">{{'custNote' | translate}}</th>
                    <th nzAlign="center">{{'custSeller' | translate}}</th>
                    <th nzAlign="center"></th>
                </tr>
            </thead>
            <tbody class="uiAdminTableBody ">
                <ng-container *ngFor="let data of dataTable.data; let i = index">
                    <tr [ngClass]="i%2 !== 0 ? 'uiColoredRow' : ''">
                        <td [nzExpand]="expandSet === data.cd_cf" (nzExpandChange)="onExpandChange(data.cd_cf, $event)">
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.cd_cf}}
                        </td>
                        <td nzLeft="0px" nzAlign="left">
                            {{data.descrizione}}
                        </td>
                        <td nzLeft="0px" nzAlign="left">
                            {{data.indirizzo}} - {{data.localita}} {{data.cap}} ({{data.cd_nazione}},
                            {{data.cd_provincia}})
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.partitaiva}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.codicefiscale}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.gruppo}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            {{data.note_cf}}
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <span *ngIf="data.codiceV">{{data.codiceV}}: {{data.nomeV}} {{data.cognomeV}}</span>
                        </td>
                        <td nzLeft="0px" nzAlign="center">
                            <i *ngIf="data.provvisorio" class="fad fa-unlock uiAdminUserAction" (click)="showModalSave(data.cd_cf)" nzTooltipTitle="{{'saveTempCust' | translate}}" nzTooltipPlacement="top" nz-tooltip></i>
                            <br />
                            <br />
                            <i *ngIf="data.provvisorio" class="fad fa-trash uiAdminUserAction" nz-popconfirm nzPopconfirmTitle="{{'deleteTempCustAlert' | translate}}" nzPopconfirmPlacement="bottom" (nzOnConfirm)="deleteCustomer(data.cd_cf)" nzTooltipTitle="{{'deleteTempCust' | translate}}" nzTooltipPlacement="top" nz-tooltip></i>
                        </td>
                    </tr>
                    <tr [nzExpand]="expandSet === data.cd_cf" class="uiCustContactTable">
                        <nz-table #dataCont nzBordered nzSize="middle" [nzData]="contResult" [nzLoading]="loading" nzShowPagination="false" [nzSimple]="true" class="uiAdminTable ">
                            <thead>
                                <tr class="uiCustContactTableHead">
                                    <th nzAlign="center">{{'custName' | translate}}</th>
                                    <th nzAlign="center">{{'custTitle' | translate}}</th>
                                    <th nzAlign="center">{{'custTel' | translate}}</th>
                                    <th nzAlign="center">{{'custTel2' | translate}}</th>
                                    <th nzAlign="center">{{'custCell' | translate}}</th>
                                    <th nzAlign="center">{{'custCell2' | translate}}</th>
                                    <th nzAlign="center">{{'custEmail' | translate}}</th>
                                    <th nzAlign="center">{{'custPec' | translate}}</th>
                                    <th nzAlign="center">{{'custUrl' | translate}}</th>
                                    <th nzAlign="center">{{'custNote' | translate}}</th>
                                </tr>
                            </thead>
            <tbody class="uiAdminTableBody">
                <tr *ngFor="let cont of dataCont.data; let i = index" class="uiContactRow">
                    <td nzLeft="0px" nzAlign="center">
                        {{cont.nome}}
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        {{cont.titolo}}
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        {{cont.telefono}}
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        {{cont.telefono2}}
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        {{cont.cellulare}}
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        {{cont.cellulare2}}
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        {{cont.email}}
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        {{cont.emailpec}}
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        <a href="{{cont.url}}" target="_blank">{{cont.url}}</a>
                    </td>
                    <td nzLeft="0px" nzAlign="center">
                        {{cont.note}}
                    </td>
                </tr>
            </tbody>
        </nz-table>
        </tr>
        </ng-container>
        </tbody>
        </nz-table>
    </nz-content>

    <nz-modal [(nzVisible)]="showSave" nzTitle="{{'custSaveCode' | translate}}" nzFooter="null" (nzOnCancel)="handleCancelSave()" class="uiCustomerModal">
        <ng-container *nzModalContent>
            <form [formGroup]="saveForm" class="uiAddForm">
                <input nz-input formControlName="code" placeholder="{{'custCode' | translate}}*" />
            </form>
        </ng-container>
        <div *nzModalFooter>
            <button nz-button nzType="primary" class="uiModalBackBtn" (click)="handleCancel()">
                {{'cancel' | translate}}
            </button>
            <button nz-button nzType="primary" [disabled]="saveForm.invalid" class="uiModalAddBtn" (click)="saveCustomer()" nzTooltipTitle="{{'update' | translate}}" nzTooltipPlacement="top" nz-tooltip>{{'tooltip.add' | translate}}</button>
        </div>
    </nz-modal>
</nz-layout>