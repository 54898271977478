<nz-layout class="uiModalLayout">
    <nz-content>
        <div *ngIf="!loading" class="uiFlexDivContainer">
            <div *ngFor="let img of dataResult; let i = index" class="uiImgContainer">
                <nz-badge [nzCount]="iconTemplate" class="uiImgBadge">
                    <button nz-button nz-popconfirm nzPopconfirmTitle="Vuoi cancellare l'immagine dal prodotto?" *ngIf="auth.getUser().ruolo !== 'utente'"
                        style="float: right; font-size: 12px; width: auto; height: auto; padding: 0; position: absolute; left: 0px; z-index: 1000;"
                         nzPopconfirmPlacement="top" (nzOnConfirm)="deleteImg(i)">
                        <i class="fad fa-trash" style="font-size: 10px; padding: 5px;"></i>
                    </button>
                    <a class="uiImgAnchor" (click)="selectImg(img)">
                        <img [src]="img.url" class="uiImg" />
                    </a>
                </nz-badge>
                <ng-template #iconTemplate>
                    <i *ngIf="img.selected" nz-icon nzType="check-circle" class="ant-scroll-number-custom-component uiSelectedIcon"
                        nzTheme="fill"></i>
                </ng-template>
            </div>
        </div>
    </nz-content>
    <div *nzModalFooter class="uiModalFooter">
        <nz-upload class="uiImgUpload" [nzShowUploadList]="false" nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76" nzAction="{{uploadURL}}" [nzCustomRequest]="customImgReq" nzAccept="image/png, image/jpeg">
            <button nz-button class="uiUploadBtn">
                <i class="fad fa-file-upload"></i>
                {{'upload' | translate}}
            </button>
        </nz-upload>
        <button nz-button nzType="primary" class="uiSaveBtn" (click)="onClickSave()">
            <i class="fad fa-plus-square"></i>
            {{'save' | translate}}
        </button>
    </div>
</nz-layout>