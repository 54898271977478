import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { TblPreventivi } from "src/app/entities/TblPreventivi";
import { DataService } from "src/app/services/data.service";
import { GlobalService } from "src/app/shared/global.service";

@Component({
	selector: "app-quotes",
	templateUrl: "./quotes.component.html",
	styleUrls: ["./quotes.component.scss"],
})
export class QuotesComponent implements OnInit {
	showForm = false;

	dataResult: TblPreventivi[];
	displayedData: any[];
	loading = true;
	sellers: { label: string }[] = [];
	expandSet = new Set<number>();

	constructor(
		private data: DataService,
		private message: NzMessageService,
		private g: GlobalService,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		// get all customers
		this.data.getTable("quotes").subscribe(
			(result) => {
				this.dataResult = result as TblPreventivi[];
				this.displayedData = [...this.dataResult];
				this.loading = false;
			},
			(error) => {
				this.message.create("error", "Errore", { nzDuration: 5000 });
				this.loading = false;
				console.error("GET TABLE: ", error);
			}
		);
	}

	filter(searchValue: string) {
		this.displayedData = this.dataResult.filter(
			(item: TblPreventivi) => item.cd_cf.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
		);
	}

	openPdf(cd) {
		let i = this.displayedData.findIndex((data) => data.cd_preventivo === cd);
		const formData = new FormData();
		formData.append("cd", this.displayedData[i].cd_preventivo);
		this.data.generatePdf(formData).subscribe(
			(result) => {
				window.open(this.g.pdfPath + result.nome, "_blank");
			},
			(error) => {
				console.error("Unable to generate pdf: ", error);
			}
		);
	}

	downloadPdf(cd) {
		let i = this.displayedData.findIndex((data) => data.cd_preventivo === cd);
		const formData = new FormData();
		formData.append("cd", this.displayedData[i].cd_preventivo);
		this.data.generatePdf(formData).subscribe(
			async (result) => {
				const base64Response = await fetch(result.file);
				const blob = await base64Response.blob();
				const fileURL = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				document.body.appendChild(a);
				a.href = fileURL;
				a.download = result.nome;
				a.target = "_blank";
				a.click();
			},
			(error) => {
				console.error("Unable to generate pdf: ", error);
			}
		);
	}
}
