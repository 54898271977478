import { Validators } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { element } from "protractor";
import { DataService } from "src/app/services/data.service";
import { GlobalService } from "src/app/shared/global.service";
import { readSync } from "fs";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  settings: any;
  loading = true;
  editCache: { [key: string]: { edit: boolean; data: any; }; } = {};
  constructor(
    public data: DataService,
    private message: NzMessageService,
    private g: GlobalService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.data.getTable("settings").subscribe(
      (result) => {
        // ************** AB 09/02/2024 **********************
        // this.settings = result;
        this.settings = result.filter(res => {
          if (res.tipo == 1) {
            res.valore = res.valore.split('|');
          }
          return res;
        });
        // **********************************************

        this.loading = false;
        this.updateEditCache();
      },
      (error) => {
        this.loading = false;
        this.message.create("error", "Errore", { nzDuration: 5000 });
        console.error("GET TABLE: ", error);
      }
    );
  }

  checkObj(val) {
    return typeof val == "object";
  }
  updateEditCache(): void {
    this.settings.forEach((item) => {
      this.editCache[item.nome] = {
        edit: false,
        data: { ...item },
      };
    });
  };

  startEdit(id: string): void {
    this.editCache[id].edit = true;
  };

  cancelEdit(id: string): void {
    const index = this.settings.findIndex((item) => item.nome.toString() === id);
    this.editCache[id] = {
      data: { ...this.settings[index] },
      edit: false,
    };
  }

  async saveEdit(id: string) {
    let tipo = this.editCache[id].data.tipo;
    let valore = "";
    if (tipo === "0" || tipo === '2') {
      valore = this.editCache[id].data.valore;
    } else {
      for (let i = 0; i < this.editCache[id].data.valore.length; i++) {
        valore = valore + this.editCache[id].data.valore[i];
        if (i < this.editCache[id].data.valore.length - 1) {
          valore = valore + "|";
        }
      }
    }
    const checkSumKey = await this.g.encryptDataGlobal("TblImpostazioni" + id + valore);
    const formData = new FormData();
    formData.append("nome", id);
    formData.append("valore", valore);
    formData.append("checkKey", checkSumKey);
    this.data.editSetting(formData).subscribe(
      (result) => {
        this.editCache[id].edit = false;
        const index = this.settings.findIndex((item) => item.nome.toString() === id);
        Object.assign(this.settings[index], this.editCache[id].data);
        this.message.create("success", "Setting edited successfully", { nzDuration: 5000 });
      },
      (error) => {
        this.message.create("error", "Errore", { nzDuration: 5000 });
        console.error("Error ", error);
      }
    );
  }

  addInput(nome) {
    this.editCache[nome].data.valore.push("");
  }

  deleteInput(nome, index) {
    this.editCache[nome].data.valore.splice(index, 1);
  }

  onValueUpdate(event, nome, j) {
    this.editCache[nome].data.valore[j] = event.target.value;
  }
}
